import { CreateDailyReportTypeDocument } from '@/shared/api/graphql/reports/documents/create-daily-report-type.document';
import type {
  TCreateDailyReportTypeInput,
  TCreateDailyReportTypeOutput,
} from '@/shared/api/graphql/reports/validators/create-daily-report-type.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const createDailyReportType = async (input: TCreateDailyReportTypeInput): Promise<void> => {
  const { createDailyReportType } = await graphqlApiCaller<TCreateDailyReportTypeOutput>(
    CreateDailyReportTypeDocument,
    input,
  );
  if (!createDailyReportType.ok) {
    throw new Error(createDailyReportType.error.message, {
      cause: createDailyReportType.error.code,
    });
  }
};
