/**
 * This function will mask all text without first and last character.
 * @param name Text which need masking.
 * @param replace Text which replacing.
 */
export const maskingCenter = (name: string, replace: string = '*') =>
  Array.from({ length: name.length })
    .map((_, index, array) => {
      const char = name.at(index);
      if (index === 0 || index === array.length - 1) return char;
      return replace;
    })
    .join('');

/**
 * This function will mask all text.
 * @param text Text which need masking.
 */
export const masking = (text?: string | null) => text?.split('').map(() => '*');
