import { GetWeeklyReportsDocument } from '@/shared/api/graphql/reports/documents/get-weekly-reports.document';
import type {
  TGetWeeklyReportsInput,
  TGetWeeklyReportsOutput,
  TGetWeeklyReportsOutputData,
} from '@/shared/api/graphql/reports/validators/get-weekly-reports.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const getWeeklyReports = async (
  input: TGetWeeklyReportsInput,
): Promise<TGetWeeklyReportsOutputData> => {
  const { weeklyReports } = await graphqlApiCaller<TGetWeeklyReportsOutput>(
    GetWeeklyReportsDocument,
    input,
  );

  if (!weeklyReports.ok) {
    throw new Error(weeklyReports.error.message, {
      cause: weeklyReports.error.code,
    });
  }
  return weeklyReports.data;
};
