import { gql } from 'graphql-request';

export const CREATE_FOCUS_MUTATION = gql`
  mutation createFocus($input: CreateFocusInput!) {
    createFocus(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;
