export const MEETING_TYPE_VALUE = {
  IN_PERSON: 'IN_PERSON',
  VIRTUAL: 'VIRTUAL',
  HYBRID: 'HYBRID',
} as const;

export type MeetingTypeValueKeys = keyof typeof MEETING_TYPE_VALUE;

export const MEETING_TYPE_TEXT = {
  IN_PERSON: '대면 회의',
  VIRTUAL: '가상 회의',
  HYBRID: '혼합형 회의',
} as const;

export const MEETING_RECURRING_CYCLE_VALUE = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
} as const;

export const MEETING_RECURRING_CYCLE_TEXT = {
  WEEKLY: '매주',
  MONTHLY: '매월',
} as const;
