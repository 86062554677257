import { type GetDailyReportType } from '@/shared/api/graphql/reports/validators/get-daily-report.validator';
import { UNKNOWN } from '@/shared/constants';
import { cn } from '@/shared/libs/style.lib';

import XIcon from '@/components/icons/XIcon';

type Props = {
  report: GetDailyReportType;
  isDeleting?: boolean;
  isDeleteVisible?: boolean;
  onItemClick?: (id: number) => void;
  onDeleteClick?: (id: number) => void;
};

const DailyReportItem = ({
  report,
  isDeleting,
  isDeleteVisible = true,
  onItemClick,
  onDeleteClick,
}: Props) => {
  const onPreDeleteClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reportId: number,
  ) => {
    event.stopPropagation();

    onDeleteClick?.(reportId);
  };

  return (
    <li
      key={report.id}
      className="flex items-center gap-2 justify-between border px-3 py-2 cursor-pointer border-pixel"
      onClick={() => onItemClick?.(report.id)}
    >
      {/* Information */}
      <div className="flex-1 space-y-1 overflow-hidden">
        <div className="flex items-center justify-between gap-2">
          {/* Title */}
          <h2 className="col-start-1 col-end-11 font-bold truncate">{report.content}</h2>

          <div className="flex items-center gap-2">
            {/* Time */}
            <small className="col-start-11 col-end-13 justify-self-end self-center text-gray-500 text-xs tracking-wider">
              {report.startTime} ~ {report.endTime}
            </small>

            {isDeleteVisible && (
              <button
                className={cn('flex items-center', { 'opacity-20': isDeleting })}
                disabled={isDeleting}
                onClick={(event) => onPreDeleteClick(event, report.id)}
              >
                <XIcon className="size-4" />
              </button>
            )}
          </div>
        </div>

        {/* Memo */}
        <p className="text-gray-500 truncate text-sm">{report.memo || '-'}</p>

        {/* Tags */}
        <ul className="flex items-center gap-x-3 gap-y-1 flex-wrap *:text-gray-400 *:text-xs *:tracking-widest">
          {/* Report type */}
          {report.work ? (
            <>
              {/* Project */}
              <li>#{report.work.project?.name ?? UNKNOWN}</li>
              {/* Work */}
              <li>#{report.work.name ?? '-'}</li>
            </>
          ) : null}
          <li>#{report.reportType?.name ?? '-'}</li>
        </ul>
      </div>
    </li>
  );
};

export default DailyReportItem;
