import { graphqlApiCaller } from '@/shared/helpers/api.helper';

import { DELETE_WORK_MUTATION } from '../documents/delete-work.document';
import { DeleteWorkInputType, DeleteWorkOutputType } from '../validators/delete-work.validator';

export const deleteWork = async (input: DeleteWorkInputType): Promise<void> => {
  const { deleteWork } = await graphqlApiCaller<DeleteWorkOutputType>(DELETE_WORK_MUTATION, input);
  if (!deleteWork.ok) {
    throw new Error(deleteWork.error.message, {
      cause: deleteWork.error.code,
    });
  }
};
