import {
  type AnimationControls,
  motion,
  type TargetAndTransition,
  type VariantLabels,
} from 'framer-motion';

import { type GetDailyReportType } from '@/shared/api/graphql/reports/validators/get-daily-report.validator';
import { cn } from '@/shared/libs/style.lib';

import { useDarkModeStore } from '@/hooks/states/useDarkMode';

import XIcon from '@/components/icons/XIcon';

export type ReportItemType = {
  report: GetDailyReportType;
  animate: AnimationControls | TargetAndTransition | VariantLabels | boolean;
  className?: string;
};

type Props = {
  item: ReportItemType;
  onReportClick?: (item: GetDailyReportType) => void;
  onDeleteClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reportId: number,
  ) => void;
};

const DailyReportTimelineCard = ({ item, onReportClick, onDeleteClick }: Props) => {
  const { isDark } = useDarkModeStore();

  return (
    <motion.li
      className={cn(
        'absolute border-pixel cursor-pointer px-2 py-1 flex flex-col gap-2 justify-between overflow-auto no-scrollbar hover:z-10 *:bg-transparent',
        isDark ? 'bg-gray-500 hover:bg-gray-400' : 'bg-gray-100 hover:bg-gray-200',
        item.className,
      )}
      animate={item.animate}
      onClick={() => onReportClick?.(item.report)}
    >
      {/* Top row */}
      <div className="flex items-center justify-between gap-2 *:bg-transparent">
        <div className="truncate text-sm tracking-wider" title={item.report.content}>
          {item.report.content}
        </div>

        <div className="flex items-center gap-4 shrink-0 *:bg-transparent">
          <small className="flex-1">
            {item.report.startTime} ~ {item.report.endTime}
          </small>

          <button className="size-4" onClick={(e) => onDeleteClick?.(e, item.report.id)}>
            <XIcon className="size-4" />
          </button>
        </div>
      </div>

      {/* Bottom row */}
      <div className="flex items-center gap-2 text-sm tracking-wider *:bg-transparent *:truncate">
        {item.report.work?.project ? (
          <small title={`# ${item.report.work.project.name}`}>
            # {item.report.work.project.name}
          </small>
        ) : null}

        {item.report.work?.name ? (
          <small title={`#${item.report.work.name}`}># {item.report.work.name}</small>
        ) : null}

        {item.report.reportType ? (
          <small title={`# ${item.report.reportType.name}`}># {item.report.reportType.name}</small>
        ) : null}
      </div>
    </motion.li>
  );
};

export default DailyReportTimelineCard;
