import Button from '@/components/Button';
import ModalWrapper from '@/components/ModalWrapper';

type Props = {
  title: string;
  content: string;
  isPending?: boolean;
  onConfirm?: () => Promise<void> | void;
  onCancel?: () => void;
  onClose?: () => void;
};

const ConfirmModal = ({ title, content, isPending, onConfirm, onCancel, onClose }: Props) => {
  return (
    <ModalWrapper className="border-pixel" title={title} isPending={isPending} onClose={onClose}>
      {/* Content */}
      <pre className="text-sm">{content}</pre>

      {/* Footer */}
      <div className="flex items-center gap-3">
        <Button className="flex-1" disabled={isPending} onClick={onConfirm}>
          Yes
        </Button>
        <Button className="flex-1" disabled={isPending} onClick={onCancel}>
          No
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default ConfirmModal;
