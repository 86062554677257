import React, { useEffect } from 'react';

import { Environments } from '@/shared/constants/environments.constant';

type Props = {
  children: React.ReactNode;
};

const KakaoProvider = ({ children }: Props) => {
  useEffect(() => {
    if (window.Kakao?.isInitialized()) return;

    window.Kakao?.init(Environments.KAKAO_SDK_KEY);
  }, []);

  return <>{children}</>;
};

export default KakaoProvider;
