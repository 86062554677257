import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { deleteDailyReport } from '@/shared/api/graphql/reports/services/delete-daily-report.service';
import { getDailyReports } from '@/shared/api/graphql/reports/services/get-daily-reports.service';
import { ROUTER_PATH } from '@/shared/constants/routes';
import { debouncer } from '@/shared/libs/debouncer.lib';

import { usePopupStore } from '@/hooks/states/usePopupStore';

import ConfirmModal from '@/components/ConfirmModal';
import Loader from '@/components/Loader';
import NoItemsYet from '@/components/NoItemsYet';
import Portal from '@/components/Portal';

import DailyReportItem from '@/features/reports/DailyReportItem';

type Props = {
  date?: string;
};

const DailyReportItems = ({ date }: Props) => {
  const { push } = usePopupStore();
  const navigate = useNavigate();
  const [debouncedDate, setDebouncedDate] = useState(date);
  const [deleteReport, setDeleteReport] = useState<number | undefined>();

  const queryClient = useQueryClient();

  useEffect(() => {
    refreshDate();
  }, [date]);

  // Queries.
  // Get daily reports query.
  const { data: dailyReports, isLoading: dailyReportsIsLoading } = useQuery({
    queryKey: ['dailyReports', debouncedDate],
    queryFn: () => getDailyReports({ date: debouncedDate }),
  });

  // Mutations.
  // Delete daily report mutation.
  const { mutateAsync: deleteDailyReportMutate, isPending: isDeleteDailyReportPending } =
    useMutation({
      mutationFn: deleteDailyReport,
      onSuccess: () => {
        push({ title: '삭제', subtitle: '정상적으로 삭제되었습니다.' });
        queryClient.invalidateQueries();
      },
      onError: (error) => {
        push({ title: '삭제', subtitle: error.message, level: 'error' });
      },
    });

  async function refreshDate() {
    if (!date) return;

    const debounced = await debouncer(() => date);
    setDebouncedDate(debounced);
  }

  const onReportDeleteClick = async (reportId: number) => {
    setDeleteReport(reportId);
  };

  const onItemClick = (reportId: number) => {
    navigate(`${ROUTER_PATH.DAILY_REPORT_UPDATE}?id=${reportId}`);
  };

  return (
    <>
      <div>
        {dailyReportsIsLoading ? (
          <Loader />
        ) : dailyReports?.length ? (
          <ul className="dynamic-grid">
            {dailyReports.map((report) => (
              <DailyReportItem
                key={report.id}
                report={report}
                isDeleting={isDeleteDailyReportPending}
                onItemClick={() => onItemClick(report.id)}
                onDeleteClick={() => onReportDeleteClick(report.id)}
              />
            ))}
          </ul>
        ) : (
          <NoItemsYet className="text-small h-5">
            업무 기록을 손쉽게 관리하기 위해 일일 리포트를 작성해보세요!
          </NoItemsYet>
        )}
      </div>

      <Portal portalId="modal">
        {deleteReport ? (
          <ConfirmModal
            title="리포트 삭제"
            content="정말 해당 리포트를 삭제하시겠어요?"
            isPending={isDeleteDailyReportPending}
            onConfirm={async () => {
              await deleteDailyReportMutate({ id: deleteReport });
              setDeleteReport(undefined);
            }}
            onClose={() => setDeleteReport(undefined)}
            onCancel={() => setDeleteReport(undefined)}
          />
        ) : null}
      </Portal>
    </>
  );
};

export default DailyReportItems;
