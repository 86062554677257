import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { motion, Variants } from 'framer-motion';
import { Bird } from 'lucide-react';

import { HorizontalDirections, HorizontalDirectionType } from '@/shared/constants';
import { computeTargetCenter } from '@/shared/helpers';

import useMousePosition from '@/hooks/inputs/useMousePosition';

type Props = {
  path: string;
  title: string;
  subtitle: string;
};

const birdVariants: Variants = {
  left: {
    rotateY: -180,
  },
  right: {
    rotateY: 0,
  },
};

const EmptyListGuider = ({ title, subtitle, path }: Props) => {
  const birdContainerRef = useRef<HTMLDivElement>(null);
  const [x, setX] = useState<HorizontalDirectionType>(HorizontalDirections.RIGHT);

  useMousePosition({ onChange: onMouseChange });

  function onMouseChange(x: number) {
    if (!birdContainerRef.current) return;

    const { horizontal } = computeTargetCenter(birdContainerRef.current);

    setX(x - horizontal < 0 ? HorizontalDirections.LEFT : HorizontalDirections.RIGHT);
  }

  return (
    <article className="flex flex-col items-center justify-center gap-10 py-10">
      <motion.div
        ref={birdContainerRef}
        className="relative"
        initial="right"
        animate={x === HorizontalDirections.LEFT ? 'left' : 'right'}
        variants={birdVariants}
      >
        <Bird className="size-24" />
        <Bird className="absolute left-0 top-0 size-24 animate-ping text-gray-300" />
      </motion.div>

      <div className="flex flex-col gap-2 justify-center items-center">
        <p className="text-gray-500 animate-pulse">{title}</p>
        <Link className="text-lg" to={path}>
          {subtitle}
        </Link>
      </div>
    </article>
  );
};

export default EmptyListGuider;
