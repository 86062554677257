import React, { useState } from 'react';

import { DateTime } from 'luxon';

import { DateFormat } from '@/shared/constants/formats.constant';
import { textCopy } from '@/shared/helpers/shared.helper';
import { cn } from '@/shared/libs/style.lib';

import { type TDateRange } from '@/pages/reports/WeeklyReportList';

import ArrowIcon from '@/components/icons/ArrowIcon';
import SquareIcon from '@/components/icons/SquareIcon';

type Props = {
  datePeriodState: [TDateRange, React.Dispatch<React.SetStateAction<TDateRange>>];
};

const CalendarPeriodInput = ({ datePeriodState }: Props) => {
  const now = DateTime.now();
  const defaultStartDate = now.startOf('week').toFormat(DateFormat);
  const defaultEndDate = now.endOf('week').toFormat(DateFormat);

  const [datePeriod, setDatePeriod] = datePeriodState;
  const [isStartDateCopied, setIsStartDateCopied] = useState(false);
  const [isEndDateCopied, setIsEndDateCopied] = useState(false);

  const onPreviousWeekClick = () => {
    const previousWeek = DateTime.fromFormat(datePeriod.start, DateFormat).minus({ week: 1 });
    const start = previousWeek.toFormat(DateFormat);
    const end = previousWeek.endOf('week').toFormat(DateFormat);

    setDatePeriod({ start, end });

    setIsStartDateCopied(false);
    setIsEndDateCopied(false);
  };

  const onNextWeekClick = () => {
    const previousWeek = DateTime.fromFormat(datePeriod.start, DateFormat).plus({ week: 1 });
    const start = previousWeek.toFormat(DateFormat);
    const end = previousWeek.endOf('week').toFormat(DateFormat);

    setDatePeriod({ start, end });

    setIsStartDateCopied(false);
    setIsEndDateCopied(false);
  };

  const onTodayClick = () => {
    setDatePeriod({ start: defaultStartDate, end: defaultEndDate });

    setIsStartDateCopied(false);
    setIsEndDateCopied(false);
  };

  /**
   * Copy 'start' date period into clipboard as text.
   */
  const onStartDateClick = () => {
    textCopy(datePeriod.start);

    setIsStartDateCopied(true);
  };

  /**
   * Copy 'end' date period into clipboard as text.
   */
  const onEndDateClick = () => {
    textCopy(datePeriod.end);

    setIsEndDateCopied(true);
  };

  return (
    <div className="flex items-center justify-between px-4 py-2 border-pixel">
      <p className="text-sm flex items-center gap-3">
        <span
          className={cn('cursor-pointer', { 'text-green-500 animate-pulse': isStartDateCopied })}
          onClick={onStartDateClick}
        >
          {datePeriod.start}
        </span>
        <span className="text-lg">~</span>
        <span
          className={cn('cursor-pointer', { 'text-green-500 animate-pulse': isEndDateCopied })}
          onClick={onEndDateClick}
        >
          {datePeriod.end}
        </span>
      </p>

      <div className="flex items-center gap-3">
        <ArrowIcon
          className="size-3 rotate-180 select-none cursor-pointer"
          onClick={onPreviousWeekClick}
        />

        <SquareIcon className="size-4 select-none cursor-pointer" onClick={onTodayClick} />

        <ArrowIcon className="size-3 select-none cursor-pointer" onClick={onNextWeekClick} />
      </div>
    </div>
  );
};

export default CalendarPeriodInput;
