import {
  DELETE_WORKING_TIME_MUTATION,
  GET_WORKING_TIME_QUERY,
  SET_WORKING_TIME_MUTATION,
} from '@/shared/api/graphql/members/documents';
import {
  type DeleteWorkingTimeInputType,
  DeleteWorkingTimeOutputType,
  GetWorkingTimeOutputDataType,
  type GetWorkingTimeOutputType,
  type SetWorkingTimeInputType,
  type SetWorkingTimeOutputType,
} from '@/shared/api/graphql/members/validators';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

/**
 * Upsert member's working time.
 */
export const setWorkingTime = async (input: SetWorkingTimeInputType) => {
  const { setWorkingTime } = await graphqlApiCaller<SetWorkingTimeOutputType>(
    SET_WORKING_TIME_MUTATION,
    input,
  );

  if (!setWorkingTime.ok) {
    throw new Error(setWorkingTime.error.message, { cause: setWorkingTime.error.code });
  }
};

/**
 * Get member's working time.
 */
export const getWorkingTime = async (): Promise<GetWorkingTimeOutputDataType> => {
  const { workingTime } = await graphqlApiCaller<GetWorkingTimeOutputType>(GET_WORKING_TIME_QUERY);

  if (!workingTime.ok) {
    throw new Error(workingTime.error.message, { cause: workingTime.error.code });
  }

  return workingTime.data;
};

/**
 * Delete member's working time.
 */
export const deleteWorkingTime = async (input: DeleteWorkingTimeInputType) => {
  const { deleteWorkingTime } = await graphqlApiCaller<DeleteWorkingTimeOutputType>(
    DELETE_WORKING_TIME_MUTATION,
    input,
  );

  if (!deleteWorkingTime.ok) {
    throw new Error(deleteWorkingTime.error.message, { cause: deleteWorkingTime.error.code });
  }
};
