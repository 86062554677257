import { gql } from 'graphql-request';

export const DeleteDailyReportDocument = gql`
  mutation deleteDailyReport($input: DeleteDailyReportInput!) {
    deleteDailyReport(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;
