import { ComponentProps } from 'react';
import type { UseFormRegisterReturn, UseFormWatch } from 'react-hook-form';

import { cn } from '@/shared/libs/style.lib';

type Props = ComponentProps<'input'> & {
  register?: UseFormRegisterReturn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch?: UseFormWatch<any>;
  text: string;
};

const CheckBoxInput = ({ register, children, id, text, className, ...rest }: Props) => {
  return (
    <>
      {children}

      <div className="field-row flex items-center gap-2 *:cursor-pointer">
        <input
          className={cn('accent-transparent', className)}
          id={id}
          type="checkbox"
          {...rest}
          {...register}
        />
        <label htmlFor={id}>{text}</label>
      </div>
    </>
  );
};

export default CheckBoxInput;
