import { X } from 'lucide-react';

import { cn } from '@/shared/libs/style.lib';
import { TCoreComponent } from '@/shared/types/component.type';

type Props = TCoreComponent & {
  title: string;
  isPending?: boolean;
  onClose?: () => void;
};

const ModalWrapper = ({ children, className, title, isPending, onClose }: Props) => {
  return (
    <div className="">
      {/* Backdrop */}
      <div className="fixed left-0 top-0 w-full h-screen opacity-80" />

      {/* Content */}
      <div
        className={cn(
          'fixed z-10 min-w-[250px] space-y-3 p-5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
          className,
        )}
      >
        {/* Header */}
        <div className="flex items-center justify-between gap-5">
          <h1 className="text-xl">{title}</h1>
          <button className="disabled:cursor-not-allowed" disabled={isPending} onClick={onClose}>
            <X />
          </button>
        </div>

        {/* Body */}
        {children}
      </div>
    </div>
  );
};

export default ModalWrapper;
