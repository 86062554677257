import { gql } from 'graphql-request';

export const GET_MEETINGS_QUERY = gql`
  query meetings {
    meetings {
      ok
      error {
        code
        message
      }
      data {
        id
        title
        location
        dateTime
        useRecurring
        useNotification
        priority
        type
        createdAt
        updatedAt
        participants {
          id
          name
        }
      }
    }
  }
`;
