import { gql } from 'graphql-request';

export const GET_PROJECTS_QUERY = gql`
  query projects {
    projects {
      ok
      error {
        code
        message
      }
      data {
        id
        name
        works {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;
