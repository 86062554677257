import { gql } from 'graphql-request';

export const DELETE_FEEDBACK_MUTATION = gql`
  mutation deleteFeedback($input: DeleteFeedbackInput!) {
    deleteFeedback(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;
