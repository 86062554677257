import { z } from 'zod';

import { GetDailyReportOutputSchema } from '@/shared/api/graphql/reports/validators/get-daily-report.validator';
import { CoreOutput } from '@/shared/types/api.type';

export const getMonthlyReportsInputSchema = z.object({
  year: z.number(),
  month: z.number(),
});

export const getMonthlyReportsOutputSchema = z.object({
  reports: z.array(GetDailyReportOutputSchema),
  charts: z.object({
    series: z.array(z.number()),
    labels: z.array(z.string()),
  }),
});

// Types.

export type GetMonthlyReportsInputType = z.infer<typeof getMonthlyReportsInputSchema>;

export type GetMonthlyReportsOutputDataType = z.infer<typeof getMonthlyReportsOutputSchema>;

export type GetMonthlyReportsOutputType = {
  monthlyReports: CoreOutput<GetMonthlyReportsOutputDataType>;
};
