// Constants.
export const ROUTER_PATH = {
  // Catch
  NOT_FOUND: '*',

  // Auth
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  FIND_PASSWORD: '/find-password',
  KAKAO_LOGIN_READY: '/sign-in/oauth/kakao/login/ready',
  KAKAO_LOGIN_SUCCESS: '/sign-in/oauth/kakao/login/success',
  KAKAO_CONNECT_READY: '/sign-in/oauth/kakao/connect/ready',
  KAKAO_CONNECT_SUCCESS: '/sign-in/oauth/kakao/connect/success',
  GOOGLE_LOGIN_SUCCESS: '/sign-in/oauth/google/login/success',

  // Home
  HOMER: '/',

  // Reports
  REPORT_LIST: '/reports',
  DAILY_REPORT_LIST: '/reports/daily',
  DAILY_REPORT_CREATE: '/reports/daily/new',
  DAILY_REPORT_UPDATE: '/reports/daily/edit',
  WEEKLY_REPORT_LIST: '/reports/weekly',
  MONTHLY_REPORT_LIST: '/reports/monthly',

  // Tasks
  TASK_LIST: '/tasks',
  TASK_CREATE: '/tasks/new',
  TASK_UPDATE: '/tasks/edit',

  // Feedbacks
  FEEDBACK_LIST: '/feedbacks',
  FEEDBACK_CREATE: '/feedbacks/new',

  // Projects
  PROJECT_LIST: '/projects',
  PROJECT_CREATE: '/projects/new',
  PROJECT_UPDATE: '/projects/edit',

  // Works
  WORK_LIST: '/works',
  WORK_CREATE: '/works/new',

  // Meetings
  MEETING_LIST: '/meetings',
  MEETING_CREATE: '/meetings/new',
  MEETING_UPDATE: '/meetings/:id',

  // Profile
  MY_PROFILE: '/profile',
  MY_PROFILE_UPDATE: '/profile/edit',

  // Time stamp
  WORKING_TIME: '/working-time',
  WORKING_TIME_UPDATE: '/working-time/edit',

  // Accounts
  ACCOUNT_LIST: '/accounts',
  ACCOUNT_CREATE: '/accounts/new',
  ACCOUNT_UPDATE: '/accounts/:id',

  // Pomodoro
  POMODORO_TIMER: '/pomodoro',
  POMODORO_STATISTICS: '/pomodoro/statistics',
} as const;
