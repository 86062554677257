import { gql } from 'graphql-request';

export const DELETE_MEMBER_MUTATION = gql`
  mutation deleteMember {
    deleteMember {
      ok
      error {
        code
        message
      }
    }
  }
`;
