export { AVATAR } from '@/shared/constants/content-type.constant';
export {
  POMODORO_BREAKING_STATUS,
  POMODORO_STATUS,
  POMODORO_STATUS_TEXT,
  POMODORO_WORKING_STATUS,
  type PomodoroBreakingStatusType,
  type PomodoroStatusType,
  type PomodoroWorkingStatusType,
} from '@/shared/constants/pomodoro.constant';

export { COLORS } from '@/shared/constants/palette.constant';

export const DATE_FORMAT = 'yyyy-MM-dd';

export const PRIORITY_VALUE = {
  LOW: 'LOW',
  MIDDLE: 'MIDDLE',
  HIGH: 'HIGH',
} as const;

export type PriorityValueType = keyof typeof PRIORITY_VALUE;

export const PRIORITY_TEXT = {
  LOW: '낮음',
  MIDDLE: '중간',
  HIGH: '높음',
} as const;

export const UNKNOWN = 'Unknown';

export const DEFAULT_ESTIMATED_MINUTES = '30';

export const HorizontalDirections = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
} as const;

export type HorizontalDirectionType = keyof typeof HorizontalDirections;

export const VerticalDirections = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
} as const;

export type VerticalDirectionType = keyof typeof VerticalDirections;

export const TIMER_STATUS = {
  STOP: 'STOP',
  START: 'START',
  PAUSE: 'PAUSE',
} as const;

export type TimerStatusKeys = keyof typeof TIMER_STATUS;

export type TimerStatusValues = (typeof TIMER_STATUS)[keyof typeof TIMER_STATUS];
