import { z } from 'zod';

import { ReportMessages } from '@/shared/constants/messages/reports.constant';
import { CoreOutput } from '@/shared/types/api.type';

export const CreateDailyReportTypeInputSchema = z.object({
  name: z.string().min(1, ReportMessages.ENTER_NAME),
});

export type TCreateDailyReportTypeInput = z.infer<typeof CreateDailyReportTypeInputSchema>;

export type TCreateDailyReportTypeOutput = {
  createDailyReportType: CoreOutput;
};
