import { DateTime } from 'luxon';

import { cn } from '@/shared/libs/style.lib';

import ArrowIcon from '../../components/icons/ArrowIcon';
import SquareIcon from '../../components/icons/SquareIcon';

export type OnMonthlyCalendarChangeData = {
  year: number;
  month: number;
};

type Props = {
  year: number;
  month: number;
  onChange?: (data: OnMonthlyCalendarChangeData) => void;
};

const MonthlyCalendarInput = ({ year, month, onChange }: Props) => {
  const onPreviousClick = () => {
    let monthResult = month - 1;
    let yearResult = year;

    // Calculate.
    if (monthResult < 1) {
      monthResult = 12;
      yearResult -= 1;
    }

    onChange?.({ year: yearResult, month: monthResult });
  };

  const onTodayClick = () => {
    const now = DateTime.now();
    onChange?.({ year: now.year, month: now.month });
  };

  const onNextClick = () => {
    let yearResult = year;
    let monthResult = month + 1;

    if (monthResult > 12) {
      yearResult += 1;
      monthResult = 1;
    }

    onChange?.({ year: yearResult, month: monthResult });
  };

  const monthAsString = String(month).padStart(2, '0');

  return (
    <div className="flex items-center justify-between px-4 py-2 border-pixel">
      <p className="text-sm flex items-center gap-3">
        <span className={cn('cursor-pointer')}>
          {year}-{monthAsString}
        </span>
      </p>

      <div className="flex items-center gap-3">
        <button onClick={onPreviousClick}>
          <ArrowIcon className="size-3 select-none cursor-pointer rotate-180" />
        </button>

        <button onClick={onTodayClick}>
          <SquareIcon className="size-4 select-none cursor-pointer" />
        </button>

        <button onClick={onNextClick}>
          <ArrowIcon className="size-3 select-none cursor-pointer" />
        </button>
      </div>
    </div>
  );
};

export default MonthlyCalendarInput;
