import { GetTasksDocument } from '@/shared/api/graphql/tasks/documents/get-tasks.document';
import { TGetTasksOutput } from '@/shared/api/graphql/tasks/validators/get-tasks.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const getTasks = async () => {
  const { tasks } = await graphqlApiCaller<TGetTasksOutput>(GetTasksDocument);
  if (!tasks.ok) {
    throw new Error(tasks.error.message, {
      cause: tasks.error.code,
    });
  }
  return tasks.data;
};
