import { ComponentProps } from 'react';

import { cn } from '@/shared/libs/style.lib';

import QuestionIcon from '@/components/icons/QuestionIcon';

type Props = ComponentProps<'div'>;

const HelpText = ({ children, className }: Props) => {
  return (
    <div className={cn('flex items-center gap-1', className)}>
      <QuestionIcon />
      <p className="text-xs text-gray-500 font-light">{children}</p>
    </div>
  );
};

export default HelpText;
