import { DELETE_MEETING_MUTATION } from '@/shared/api/graphql/meetings/documents/delete-meeting.document';
import type {
  DeleteMeetingInputType,
  DeleteMeetingOutputType,
} from '@/shared/api/graphql/meetings/validators/delete-meeting.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const deleteMeeting = async (input: DeleteMeetingInputType): Promise<void> => {
  const { deleteMeeting } = await graphqlApiCaller<DeleteMeetingOutputType>(
    DELETE_MEETING_MUTATION,
    input,
  );
  if (!deleteMeeting.ok) {
    throw new Error(deleteMeeting.error.message, {
      cause: deleteMeeting.error.code,
    });
  }
};
