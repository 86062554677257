import { gql } from 'graphql-request';

export const GetTasksDocument = gql`
  query tasks {
    tasks {
      ok
      error {
        code
        message
      }
      data {
        id
        title
        memo
        due
        status
        priority
        index
        estimatedMinutes
        createdAt
        updatedAt
      }
    }
  }
`;
