import { GetDailyReportType } from '@/shared/api/graphql/reports/validators/get-daily-report.validator';
import { UNKNOWN } from '@/shared/constants';

import { usePopupStore } from '@/hooks/states/usePopupStore';

export const makeCopyReportText = (report: GetDailyReportType): string => {
  const workText = report.work
    ? `${report.work.project?.name ?? UNKNOWN}:${report.work.name}\n`
    : '';
  const reportTypeName = report.reportType?.name ?? '-';
  const content = report.content;
  const memo = report.memo;

  return `${workText}[${reportTypeName}] ${content}\n${memo}`;
};

export const textCopy = (text: string) => {
  const state = usePopupStore.getState();
  navigator.clipboard.writeText(text);
  state.push({ title: '클립보드 복사', subtitle: '정상적으로 텍스트가 복사됐습니다.' });
};

export const copyReport = (report: GetDailyReportType) => {
  const text = makeCopyReportText(report);
  textCopy(text);
};
