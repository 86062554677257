import { GetDailyReportsDocument } from '@/shared/api/graphql/reports/documents/get-daily-reports.document';
import { GetDailyReportType } from '@/shared/api/graphql/reports/validators/get-daily-report.validator';
import {
  TGetDailyReportsInput,
  TGetDailyReportsOutput,
} from '@/shared/api/graphql/reports/validators/get-daily-reports.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const getDailyReports = async (
  input: TGetDailyReportsInput,
): Promise<GetDailyReportType[]> => {
  const { dailyReports } = await graphqlApiCaller<TGetDailyReportsOutput>(
    GetDailyReportsDocument,
    input,
  );
  if (!dailyReports.ok) {
    throw new Error(dailyReports.error.message, {
      cause: dailyReports.error.code,
    });
  }
  return dailyReports.data;
};
