import { graphqlApiCaller } from '@/shared/helpers/api.helper';

import { DELETE_PROJECT_MUTATION } from '../documents/delete-project.document';
import {
  DeleteProjectInputType,
  DeleteProjectOutputType,
} from '../validators/delete-project.validator';

export const deleteProject = async (input: DeleteProjectInputType): Promise<void> => {
  const { deleteProject } = await graphqlApiCaller<DeleteProjectOutputType>(
    DELETE_PROJECT_MUTATION,
    input,
  );
  if (!deleteProject.ok) {
    throw new Error(deleteProject.error.message, {
      cause: deleteProject.error.code,
    });
  }
};
