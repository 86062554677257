import { ComponentProps } from 'react';

import IdleBlackIcon from '@/shared/assets/icons/ring_idle_black.png';
import IdleWhiteIcon from '@/shared/assets/icons/ring_idle_white.png';
import VibrateBlackIcon from '@/shared/assets/icons/ring_vibrate_black.png';
import VibrateWhiteIcon from '@/shared/assets/icons/ring_vibrate_white.png';

import { useDarkModeStore } from '@/hooks/states/useDarkMode';

import Image from '@/components/elements/Image';

type Props = ComponentProps<'img'> & {
  status: 'IDLE' | 'VIBRATE';
};

const RingIcon = ({ status, ...rest }: Props) => {
  const { isDark } = useDarkModeStore();

  const getImageSource = () => {
    switch (status) {
      case 'IDLE':
        return isDark ? IdleWhiteIcon : IdleBlackIcon;
      case 'VIBRATE':
        return isDark ? VibrateWhiteIcon : VibrateBlackIcon;
    }
  };

  return <Image src={getImageSource()} {...rest} />;
};

export default RingIcon;
