import { gql } from 'graphql-request';

export const CreateDailyReportTypeDocument = gql`
  mutation createDailyReportType($input: CreateDailyReportTypeInput!) {
    createDailyReportType(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;
