import { DeleteTaskDocument } from '@/shared/api/graphql/tasks/documents/delete-task.document';
import type {
  TDeleteTaskInput,
  TDeleteTaskOutput,
} from '@/shared/api/graphql/tasks/validators/delete-task.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const deleteTask = async (input: TDeleteTaskInput) => {
  const { deleteTask } = await graphqlApiCaller<TDeleteTaskOutput>(DeleteTaskDocument, input);
  if (!deleteTask.ok) {
    throw new Error(deleteTask.error.message, {
      cause: deleteTask.error.code,
    });
  }
};
