import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { deleteWork } from '@/shared/api/graphql/works/services/delete-work.service';
import { getWorks } from '@/shared/api/graphql/works/services/get-works.service';
import { ROUTER_PATH } from '@/shared/constants/routes';

import { usePopupStore } from '@/hooks/states/usePopupStore';

import Button from '@/components/Button';
import ConfirmModal from '@/components/ConfirmModal';
import QuestionIcon from '@/components/icons/QuestionIcon';
import Loader from '@/components/Loader';
import Portal from '@/components/Portal';

import WorkItems from '@/features/works/WorkItems';

const WorkList = () => {
  const { push } = usePopupStore();
  const queryClient = useQueryClient();

  const [selectedWorkId, setSelectedWorkId] = useState<number | undefined>();

  const { data: worksData, isLoading: worksIsLoading } = useQuery({
    queryKey: ['works'],
    queryFn: getWorks,
  });

  const { mutateAsync: deleteWorkMutate, isPending: deleteWorkIsPending } = useMutation({
    mutationFn: deleteWork,
    onSuccess: () => {
      push({ title: '작업 삭제', subtitle: '정상적으로 삭제되었습니다.' });

      queryClient.invalidateQueries({ queryKey: ['works'] });
    },
    onError: (error) => {
      push({ title: '작업 삭제', subtitle: error.message, level: 'warning' });
    },
  });

  const onDeleteClick = (workId: number) => {
    setSelectedWorkId(workId);
  };

  return (
    <>
      <section className="space-y-2">
        <div className="flex items-center justify-between gap-5">
          <div className="flex items-center gap-5">
            <h1 className="font-bold tracking-wider">작업 목록</h1>
          </div>

          <Link className="no-link" to={ROUTER_PATH.WORK_CREATE}>
            <Button>추가</Button>
          </Link>
        </div>

        <div className="flex items-stretch gap-2">
          <QuestionIcon />
          <p className="text-gray-500 font-light text-xs">
            새로운 작업을 추가하세요! 정량적인 수치로 데이터를 더 쉽게 확인할 수 있어요.
          </p>
        </div>
      </section>

      <section>
        {worksIsLoading ? (
          <Loader />
        ) : (
          <WorkItems
            disabled={deleteWorkIsPending}
            items={worksData}
            onDeleteClick={onDeleteClick}
          />
        )}
      </section>

      <Portal portalId="modal">
        {selectedWorkId ? (
          <ConfirmModal
            title="작업 삭제"
            content={
              deleteWorkIsPending ? '작업을 삭제하고 있습니다.' : '해당 작업을 삭제하시겠어요?'
            }
            isPending={deleteWorkIsPending}
            onConfirm={async () => {
              await deleteWorkMutate({ id: selectedWorkId });
              setSelectedWorkId(undefined);
            }}
            onClose={() => setSelectedWorkId(undefined)}
            onCancel={() => setSelectedWorkId(undefined)}
          />
        ) : null}
      </Portal>
    </>
  );
};

export default WorkList;
