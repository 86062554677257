import { gql } from 'graphql-request';

export const GET_WORKS_QUERY = gql`
  query works {
    works {
      ok
      error {
        code
        message
      }
      data {
        id
        name
        project {
          id
          name
        }
        reports {
          id
          content
          reportType {
            id
            name
          }
        }
      }
    }
  }
`;
