import { motion } from 'framer-motion';

import { cn } from '@/shared/libs/style.lib';

import { type TPopup, usePopupStore } from '@/hooks/states/usePopupStore';

type Props = {
  popup: TPopup;
};

const PopupCard = ({ popup }: Props) => {
  const { remove } = usePopupStore();

  const onCardClick = () => {
    remove(popup.id);
  };

  return (
    <motion.li
      className={cn(
        'w-72 relative border border-green-500 p-2 overflow-hidden border-pixel cursor-pointer',
        { 'border-red-500': popup.level === 'error' },
        { 'border-orange-500': popup.level === 'warning' },
      )}
      initial={{}}
      animate={{ x: -10, y: -10 }}
      exit={{ y: 0, height: 0, paddingBlock: 0, marginTop: 0 }}
      onClick={onCardClick}
    >
      <small className="font-light truncate block text-sm mb-2">{popup.title}</small>

      <p className="tracking-wider text-xs mb-2 text-balance">{popup.subtitle}</p>

      <motion.div
        className="absolute bottom-0 left-0 right-0 bg-red-500 h-2 light-ignore dark-ignore"
        initial={{
          width: 0,
          backgroundColor: 'rgb(34 197 94)',
        }}
        animate={{
          width: '100%',
          backgroundColor: 'rgb(239 68 68)',
          transition: {
            duration: (popup.timeout ?? 3000) / 1000,
          },
        }}
        exit={{ opacity: 0, height: 0 }}
      />
    </motion.li>
  );
};

export default PopupCard;
