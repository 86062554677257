import { motion } from 'framer-motion';

import Moon from '@/shared/assets/icons/moon.png';
import Sun from '@/shared/assets/icons/sun.png';
import { cn } from '@/shared/libs/style.lib';

import { useDarkModeStore } from '@/hooks/states/useDarkMode';

const variants = {
  light: {
    x: 0,
    backgroundColor: '#f8fafc',
    rotate: '90deg',
    transition: {
      backgroundColor: { delay: -0.2, duration: 0.2 },
      x: { delay: 0.3, duration: 0.3 },
      rotate: { delay: 0.3, duration: 0.3 },
    },
  },
  dark: {
    x: '100%',
    backgroundColor: '#27272a',
    rotate: '180deg',
    transition: {
      backgroundColor: { delay: -0.2, duration: 0.2 },
      x: { delay: 0.3, duration: 0.3 },
      rotate: { delay: 0.3, duration: 0.3 },
    },
  },
};

const DarkModer = () => {
  const { isDark, toggle } = useDarkModeStore();

  return (
    <button
      className={cn('relative w-[50px] border p-1 flex items-center transition border-pixel')}
      onClick={toggle}
    >
      <motion.p
        className="w-5 h-5 text-xs flex items-center justify-center border rounded-md"
        variants={variants}
        animate={isDark ? 'dark' : 'light'}
      >
        <img
          className="bg-transparent"
          src={isDark ? Moon : Sun}
          style={{ rotate: isDark ? '-180deg' : '0deg' }}
          alt="Mode icon"
        />
      </motion.p>
    </button>
  );
};

export default DarkModer;
