import { graphqlApiCaller } from '@/shared/helpers/api.helper';

import { GET_PROJECTS_QUERY } from '../documents/get-projects.document';
import {
  GetProjectsOutputDataType,
  GetProjectsOutputType,
} from '../validators/get-projects.validator';

export const getProjects = async (): Promise<GetProjectsOutputDataType[]> => {
  const { projects } = await graphqlApiCaller<GetProjectsOutputType>(GET_PROJECTS_QUERY);
  if (!projects.ok) {
    throw new Error(projects.error.message, {
      cause: projects.error.code,
    });
  }
  return projects.data;
};
