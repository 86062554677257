import { ComponentProps, useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { getWorkingTime } from '@/shared/api/graphql/members/services';
import { cn } from '@/shared/libs/style.lib';

import { computeLeftTime, getFullyRelativeTime } from '@/hooks/states/useWorkingTimeStore';

import BatteryIcon from '@/components/icons/BatteryIcon';

type Props = ComponentProps<'section'>;

const LeftTime = ({ className, ...rest }: Props) => {
  const { data: workingTimeData } = useQuery({
    queryKey: ['workingTime'],
    queryFn: getWorkingTime,
  });

  const [relativeTime, setRelativeTime] = useState('-');
  const [battery, setBattery] = useState<'FULL' | 'HIGH' | 'MIDDLE' | 'LOW' | undefined>();

  const intervalId = useRef<number | undefined>();

  useEffect(() => {
    if (!workingTimeData || !workingTimeData.isDisplayEndTime) {
      return () => {
        window.clearInterval(intervalId.current);
      };
    }

    intervalId.current = window.setInterval(() => {
      const remainedTime = computeLeftTime(workingTimeData);
      if (remainedTime) {
        const time = getFullyRelativeTime(workingTimeData, remainedTime);
        setRelativeTime(time);

        const levelReference = workingTimeData.totalTime / 5;

        if (remainedTime.hours > levelReference * 4) setBattery('FULL');
        else if (remainedTime.hours > levelReference * 3) setBattery('HIGH');
        else if (remainedTime.hours > levelReference * 2) setBattery('MIDDLE');
        else if (remainedTime.hours > levelReference * 1) setBattery('LOW');
        else setBattery(undefined);
      }
    }, 1000);

    return () => {
      window.clearInterval(intervalId.current);
    };
  }, [workingTimeData]);

  return (
    <section
      className={cn('flex items-center gap-2', className)}
      title="퇴근 까지 남은 시간"
      {...rest}
    >
      <BatteryIcon level={battery} />

      <small className="text-xs">{relativeTime}</small>
    </section>
  );
};

export default LeftTime;
