export const Environments = {
  //
  IS_DEV: import.meta.env.DEV,
  MODE: import.meta.env.MODE,

  // Server
  GRAPHQL_SERVER_URL: import.meta.env.VITE_APP_GRAPHQL_SERVER_URL,

  // Firebase
  FIREBASE_API_KEY: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: import.meta.env.VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  FIREBASE_VAPID_KEY: import.meta.env.VITE_APP_FIREBASE_VAPID_KEY,

  // Sentry
  SENTRY_DSN: import.meta.env.VITE_APP_SENTRY_DSN,

  // Cloudflare Images
  CLOUDFLARE_IMAGES_ACCOUNT_ID: import.meta.env.VITE_APP_CLOUDFLARE_IMAGES_ACCOUNT_ID,
  CLOUDFLARE_IMAGES_API_TOKEN: import.meta.env.VITE_APP_CLOUDFLARE_IMAGES_API_TOKEN,

  // Kakao
  KAKAO_SDK_KEY: import.meta.env.VITE_APP_KAKAO_SDK_KEY,
  KAKAO_LOGIN_REDIRECT_URL: import.meta.env.VITE_APP_KAKAO_LOGIN_REDIRECT_URL,
  KAKAO_CONNECT_REDIRECT_URL: import.meta.env.VITE_APP_KAKAO_CONNECT_REDIRECT_URL,

  // Google
  GOOGLE_CLIENT_ID: import.meta.env.VITE_APP_GOOGLE_CLIENT_ID,
  GOOGLE_REDIRECT_URL: import.meta.env.VITE_APP_GOOGLE_REDIRECT_URL,
} as const;
