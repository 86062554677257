import { GraphQLClient } from 'graphql-request';

import { Environments } from '@/shared/constants/environments.constant';
import { getTimeZone } from '@/shared/helpers/date.helper';

export const graphqlClient = new GraphQLClient(Environments.GRAPHQL_SERVER_URL, {
  headers: {
    'time-zone': getTimeZone(),
  },
});
