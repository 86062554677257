import { ComponentProps } from 'react';

import { cn } from '@/shared/libs/style.lib';

import DeleteIcon from '@/components/icons/DeleteIcon';

const PillDelete = ({ children, className, ...rest }: ComponentProps<'button'>) => {
  return (
    <button
      {...rest}
      className={cn(
        'group border border-black px-2 py-1 text-sm flex items-center justify-between gap-1 leading-3 border-pixel',
        className,
      )}
    >
      {children}

      <DeleteIcon className="size-3" />
    </button>
  );
};

export default PillDelete;
