import { gql } from 'graphql-request';

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;
