import { gql } from 'graphql-request';

export const DELETE_PROJECT_MUTATION = gql`
  mutation deleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;
