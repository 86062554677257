import { SyntheticEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { deleteTask } from '@/shared/api/graphql/tasks/services/delete-task.service';
import { getTasks } from '@/shared/api/graphql/tasks/services/get-tasks.service';
import { ROUTER_PATH } from '@/shared/constants/routes';
import { ProgressStatus, ProgressStatusType } from '@/shared/constants/tasks.constant';
import { cn } from '@/shared/libs/style.lib';

import { usePopupStore } from '@/hooks/states/usePopupStore';

import Button from '@/components/Button';
import ConfirmModal from '@/components/ConfirmModal';
import HelpText from '@/components/HelpText';
import ArrowIcon from '@/components/icons/ArrowIcon';
import Loader from '@/components/Loader';
import NoItemsYet from '@/components/NoItemsYet';

import TaskItem from '@/features/tasks/TaskItem';

const TaskList = () => {
  const { push } = usePopupStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [deletingTaskId, setDeletingTaskId] = useState<number | undefined>(undefined);

  const [runningVisible, setRunningVisible] = useState(true);
  const [pendingVisible, setPendingVisible] = useState(true);
  const [doneVisible, setDoneVisible] = useState(true);

  // Queries.
  const { data: tasksData, isLoading: tasksIsLoading } = useQuery({
    queryKey: ['tasks'],
    queryFn: getTasks,
  });

  // Mutations.
  const { mutateAsync: deleteTaskMutate, isPending: deleteTaskIsPending } = useMutation({
    mutationFn: deleteTask,
    onSuccess: () => {
      push({ title: '삭제', subtitle: '정상적으로 삭제되었습니다.' });

      queryClient.invalidateQueries({
        queryKey: ['tasks'],
      });
    },
    onError: (error) => {
      push({ title: '삭제', subtitle: error.message, level: 'error' });
    },
  });

  const onDeleteClick = (taskId: number) => {
    setDeletingTaskId(taskId);
  };

  const onEditClick = (taskId: number) => {
    const params = new URLSearchParams();
    params.append('taskId', taskId + '');

    navigate(`${ROUTER_PATH.TASK_UPDATE}?${params.toString()}`);
  };

  const pendingTasks = tasksData?.filter((task) => task.status === 'PENDING');
  const runningTasks = tasksData?.filter((task) => task.status === 'RUNNING');
  const doneTasks = tasksData?.filter((task) => task.status === 'DONE');

  const onDetailsToggle = (
    event: SyntheticEvent<HTMLDetailsElement, Event>,
    kind: ProgressStatusType,
  ) => {
    if ('open' in event.target && typeof event.target.open === 'boolean') {
      switch (kind) {
        case ProgressStatus.PENDING:
          setPendingVisible(event.target.open);
          break;

        case ProgressStatus.RUNNING:
          setRunningVisible(event.target.open);
          break;

        case ProgressStatus.DONE:
          setDoneVisible(event.target.open);
          break;
      }
    }
  };

  return (
    <>
      <section className="space-y-2">
        <div className="flex items-center justify-between">
          <h1 className="font-bold tracking-wider">해야할 작업 목록</h1>

          <Link className="no-link" to={ROUTER_PATH.TASK_CREATE}>
            <Button>추가</Button>
          </Link>
        </div>

        <HelpText className="items-stretch">
          리포트와는 달리 <u>예정된 작업</u>을 추가하세요. 작업 현황을 한 눈에 확인할 수 있어요.
        </HelpText>
      </section>

      <section>
        {tasksIsLoading ? (
          <Loader />
        ) : tasksData?.length ? (
          <div className="space-y-5">
            {/* Running list */}
            <details
              className="space-y-2"
              open
              onToggle={(e) => onDetailsToggle(e, ProgressStatus.RUNNING)}
            >
              <summary className="cursor-pointer flex items-center justify-between gap-2">
                <h2>진행중인 작업 목록</h2>
                <ArrowIcon
                  className={cn('size-2.5 transition select-none rotate-90', {
                    '-rotate-90': runningVisible,
                  })}
                />
              </summary>

              <ul className="dynamic-grid">
                {runningTasks?.map((task) => (
                  <TaskItem
                    key={task.id}
                    task={task}
                    onEditClick={() => onEditClick(task.id)}
                    onDeleteClick={() => onDeleteClick(task.id)}
                  />
                ))}
              </ul>
            </details>

            {/* Pending list */}
            <details
              className="space-y-2"
              open
              onToggle={(e) => onDetailsToggle(e, ProgressStatus.PENDING)}
            >
              <summary className="cursor-pointer flex items-center justify-between gap-2">
                <h2>보류중인 작업 목록</h2>
                <ArrowIcon
                  className={cn('size-2.5 transition select-none rotate-90', {
                    '-rotate-90': pendingVisible,
                  })}
                />
              </summary>
              <ul className="dynamic-grid">
                {pendingTasks?.map((task) => (
                  <TaskItem
                    key={task.id}
                    task={task}
                    onEditClick={() => onEditClick(task.id)}
                    onDeleteClick={() => onDeleteClick(task.id)}
                  />
                ))}
              </ul>
            </details>

            {/* Done list */}
            <details
              className="space-y-2"
              open
              onToggle={(e) => onDetailsToggle(e, ProgressStatus.DONE)}
            >
              <summary className="cursor-pointer flex items-center justify-between gap-2">
                <h2>완료된 작업 목록</h2>
                <ArrowIcon
                  className={cn('size-2.5 transition select-none rotate-90', {
                    '-rotate-90': doneVisible,
                  })}
                />
              </summary>
              <ul className="dynamic-grid">
                {doneTasks?.map((task) => (
                  <TaskItem
                    key={task.id}
                    task={task}
                    onEditClick={() => onEditClick(task.id)}
                    onDeleteClick={() => onDeleteClick(task.id)}
                  />
                ))}
              </ul>
            </details>
          </div>
        ) : (
          <NoItemsYet>생성된 작업이 없습니다.</NoItemsYet>
        )}
      </section>

      {deletingTaskId ? (
        <ConfirmModal
          title="작업 삭제"
          content="정말 해당 작업을 삭제하시겠어요?"
          isPending={deleteTaskIsPending}
          onConfirm={async () => {
            await deleteTaskMutate({ id: deletingTaskId });
            setDeletingTaskId(undefined);
          }}
          onClose={() => {
            setDeletingTaskId(undefined);
          }}
          onCancel={() => {
            setDeletingTaskId(undefined);
          }}
        />
      ) : null}
    </>
  );
};

export default TaskList;
