import { DeleteDailyReportDocument } from '@/shared/api/graphql/reports/documents/delete-daily-report.document';
import {
  TDeleteDailyReportInput,
  TDeleteDailyReportOutput,
} from '@/shared/api/graphql/reports/validators/delete-daily-report.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const deleteDailyReport = async (input: TDeleteDailyReportInput) => {
  const { deleteDailyReport } = await graphqlApiCaller<TDeleteDailyReportOutput>(
    DeleteDailyReportDocument,
    input,
  );
  if (!deleteDailyReport.ok) {
    throw new Error(deleteDailyReport.error.message, {
      cause: deleteDailyReport.error.code,
    });
  }
};
