import { useSearchParams } from 'react-router-dom';

import { Clipboard } from 'lucide-react';

import { type GetDailyReportType } from '@/shared/api/graphql/reports/validators/get-daily-report.validator';
import { UNKNOWN } from '@/shared/constants';
import { copyReport } from '@/shared/helpers/shared.helper';
import { cn } from '@/shared/libs/style.lib';

type Props = {
  report: GetDailyReportType;
  onItemClick?: (id: number) => void;
};

const COPY_REPORT_ID_KEY = 'copyReportId';

const WeeklyReportItem = ({ report, onItemClick }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isCopiedReportId = report.id + '' === searchParams.get(COPY_REPORT_ID_KEY);

  const onClipBoardClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    copyReport(report);

    searchParams.set(COPY_REPORT_ID_KEY, report.id + '');
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <li
      key={report.id}
      className="space-y-2 border px-3 py-2 cursor-pointer overflow-hidden border-pixel"
      onClick={() => onItemClick?.(report.id)}
    >
      <div className="flex items-center justify-between gap-2">
        {/* Title */}
        <h2 className="flex-1 font-bold truncate">{report.content}</h2>

        {/* Report period & time */}
        <small className="col-start-7 col-end-13 text-end text-gray-500 text-xs tracking-wider">
          {report.date} - {report.time}
        </small>
      </div>

      {/* Memo */}
      <p className="text-gray-500 truncate text-sm">{report.memo || '-'}</p>

      <div className="flex items-center justify-between">
        <ul className="flex items-center gap-x-3 gap-y-1 flex-wrap *:text-xs *:text-gray-400 *:tracking-widest">
          {report.work ? (
            <>
              {/* Project */}
              <li>#{report.work.project?.name ?? UNKNOWN}</li>
              {/* Work */}
              <li>#{report.work.name ?? '-'}</li>
            </>
          ) : null}

          {/* Report type */}
          {report.reportType?.name ? <li>#{report.reportType.name}</li> : null}
        </ul>

        {/* Actions */}
        <div className="flex items-center self-start">
          <button onClick={onClipBoardClick}>
            <Clipboard
              className={cn(
                isCopiedReportId ? 'stroke-green-500 animate-bounce' : 'stroke-gray-400',
              )}
              size={18}
            />
          </button>
        </div>
      </div>
    </li>
  );
};

export default WeeklyReportItem;
