import {
  ComponentProps,
  forwardRef,
  ReactNode,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {
  animate,
  AnimatePresence,
  HTMLMotionProps,
  motion,
  useDragControls,
  useMotionValue,
  useTransform,
} from 'framer-motion';

import { cn } from '@/shared/libs/style.lib';

import Button from '@/components/Button';
import Portal from '@/components/Portal';

type Props = ComponentProps<'button'> & {
  headerContent?: ReactNode;
  bodyContent?: ReactNode;
  buttonText?: string;
  articleProps?: HTMLMotionProps<'article'>;
  onClose?: () => void;
};

export type BottomDrawerHandlerType = {
  close: () => void;
};

const BottomDrawer = forwardRef(
  (
    {
      bodyContent,
      headerContent,
      buttonText = 'Button',
      onClose,
      children,
      articleProps,
      ...rest
    }: Props,
    ref: Ref<BottomDrawerHandlerType>,
  ) => {
    const { className: articleClassName, ...articleRest } = articleProps ?? {};

    useImperativeHandle(ref, () => ({
      close: () => {
        onBackdropClick();
      },
    }));

    const [isOpen, setIsOpen] = useState(false);

    const holderRef = useRef<HTMLDivElement>(null);
    const mustBeClose = useRef(false);

    const dragControls = useDragControls();
    const y = useMotionValue(0);
    const drawerOpacity = useTransform(y, [0, 400], [1, 0]);
    const backdropOpacity = useTransform(drawerOpacity, [0, 1], [0, 0.3]);

    // When open drawer click event handler.
    const onDrawerOpenClick = () => {
      // Rest height position of drawer.
      animate(y, 0);

      setIsOpen(true);
    };

    /**
     * When backdrop click event handler.
     */
    const onBackdropClick = () => {
      // Close this drawer.
      setIsOpen(false);
    };

    /**
     * When drag moving event handler.
     */
    const onDrag = (event: PointerEvent) => {
      if (!holderRef.current) return;

      const diff = window.innerHeight - event.y;

      animate(y, diff);

      mustBeClose.current = diff <= 50;
    };

    /**
     * When drag end event handler.
     */
    const onDragEnd = () => {
      if (!mustBeClose.current) {
        animate(y, 0);

        return;
      }

      animate(y, 300);

      // Drawer must be close.
      setIsOpen(false);
    };

    /**
     * When any key down event handler.
     */
    const onKeyPress = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Escape':
          onBackdropClick();
          break;
      }
    };

    /**
     * When changed 'isOpen' state value.
     */
    useEffect(() => {
      if (!isOpen) onClose?.();
    }, [isOpen]);

    useEffect(() => {
      window.addEventListener('keydown', onKeyPress);

      return () => {
        window.removeEventListener('keydown', onKeyPress);
      };
    }, []);

    return (
      <>
        <Button onClick={onDrawerOpenClick} {...rest}>
          {buttonText}
        </Button>

        <Portal portalId="drawer">
          <AnimatePresence>
            {isOpen && (
              <div className="h-screen w-full relative">
                {/* Backdrop */}
                <motion.div
                  style={{
                    opacity: backdropOpacity,
                  }}
                  className="fixed h-full w-full bg-black opacity-80"
                  onClick={onBackdropClick}
                />

                <motion.article
                  className={cn(
                    'fixed bottom-0 w-full p-6 space-y-4 overflow-auto max-h-full',
                    articleClassName,
                  )}
                  style={{ y, opacity: drawerOpacity, touchAction: 'none' }}
                  initial={{ y: 400 }}
                  animate={{ y: 0, transition: { type: 'tween' } }}
                  exit={{ y: 400, transition: { type: 'tween' } }}
                  drag="y"
                  dragConstraints={{ top: 0 }}
                  dragElastic={0}
                  dragControls={dragControls}
                  dragListener={false}
                  onDrag={onDrag}
                  onDragEnd={onDragEnd}
                  {...articleRest}
                >
                  <div className="flex items-center justify-center">
                    {/* Holder */}
                    <div
                      ref={holderRef}
                      onPointerDown={(e) => {
                        dragControls.start(e);
                      }}
                      className="w-32 h-2 border-pixel cursor-pointer hover:bg-gray-300"
                    />
                  </div>

                  {children ? (
                    children
                  ) : (
                    <>
                      {/* Header */}
                      {headerContent}

                      {/* Body */}
                      {bodyContent}
                    </>
                  )}
                </motion.article>
              </div>
            )}
          </AnimatePresence>
        </Portal>
      </>
    );
  },
);

export default BottomDrawer;
