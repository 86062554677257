import { cn } from '@/shared/libs/style.lib';
import { TCoreComponent } from '@/shared/types/component.type';

type Props = TCoreComponent;

const Body = ({ className, children }: Props) => {
  return <section className={cn('w-full p-5 flex-1', className)}>{children}</section>;
};

export default Body;
