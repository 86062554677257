import { ComponentProps } from 'react';
import { UseFormRegisterReturn, UseFormWatch } from 'react-hook-form';

import { cn } from '@/shared/libs/style.lib';

import useSubmitShortcut from '@/hooks/inputs/useSubmitShortcut';

type Props = ComponentProps<'textarea'> & {
  register?: UseFormRegisterReturn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch?: UseFormWatch<any>;
  error?: string;
  actionButton?: React.ReactNode;
  inputWrapperClassName?: string;
  showTextLength?: boolean;

  onSubmit?: () => void | Promise<void>;
};

const TextAreaInput = ({
  className,
  inputWrapperClassName,
  id,
  children,
  register,
  watch,
  error,
  actionButton,
  showTextLength = true,
  onSubmit,
  ...rest
}: Props) => {
  // Hooks.
  const { onKeyDown, onKeyUp } = useSubmitShortcut<HTMLTextAreaElement>({ onSubmit });

  const currentText = watch?.(register?.name ?? '');

  // Functions.

  return (
    <div className="space-y-2">
      {children ? <label htmlFor={id}>{children}</label> : null}

      <div
        className={cn('flex items-center justify-between gap-2 relative', inputWrapperClassName)}
      >
        <textarea
          id={id}
          className={cn(
            'px-3 py-1.5 min-w-[50px] grow disabled:cursor-not-allowed border-pixel',
            className,
          )}
          autoCapitalize="off"
          autoComplete="off"
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          {...rest}
          {...register}
        ></textarea>

        {/* Current text length. */}
        {currentText && showTextLength ? (
          <small className="absolute bottom-2 right-6 text-gray-400 tracking-widest">
            {currentText.length}
            {rest.maxLength ? ` / ${rest.maxLength}` : ''}
          </small>
        ) : null}

        {actionButton}
      </div>

      {error ? <p className="error">{error}</p> : null}
    </div>
  );
};

export default TextAreaInput;
