import { gql } from 'graphql-request';

export const GET_FEEDBACKS_QUERY = gql`
  query feedbacks($input: GetFeedbacksInput!) {
    feedbacks(input: $input) {
      ok
      error {
        code
        message
      }
      data {
        id
        title
        content
        imageUrl
        priority
        status
        isSolved
        createdAt
        updatedAt
        member {
          id
          name
        }
      }
    }
  }
`;
