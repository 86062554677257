import { gql } from 'graphql-request';

export const CreateDailyReportDocument = gql`
  mutation createDailyReport($input: CreateDailyReportInput!) {
    createDailyReport(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;
