import { ComponentProps } from 'react';

import { cn } from '@/shared/libs/style.lib';

type Props = ComponentProps<'div'> & {
  name?: string;
  file?: File;
  url?: string;
  alt?: string;
};

const Avatar = ({ className, name, file, url, alt }: Props) => {
  const firstLetterFromEmail = (name?.at(0) ?? '-').toUpperCase();

  const imagePreview = () => {
    try {
      if (!file) return null;

      return URL.createObjectURL(file);
    } catch (err) {
      console.warn(err);
      return null;
    }
  };

  const previewImage = imagePreview();

  return (
    <div
      className={cn(
        'size-32 border border- border-dashed rounded-full flex items-center justify-center overflow-hidden',
        className,
      )}
    >
      {name ? <p className="text-6xl bg-transparent">{firstLetterFromEmail}</p> : null}
      {previewImage ? <img className="size-36" src={previewImage} alt={alt} /> : null}
      {url ? <img className="" src={url} alt={alt} /> : null}
    </div>
  );
};

export default Avatar;
