import { ComponentProps } from 'react';

import BlackIcon from '@/shared/assets/icons/repeat_black.png';
import WhiteIcon from '@/shared/assets/icons/repeat_white.png';

import { useDarkModeStore } from '@/hooks/states/useDarkMode';

import Image from '@/components/elements/Image';

type Props = ComponentProps<'img'>;

const RepeatIcon = (props: Props) => {
  const { isDark } = useDarkModeStore();

  return <Image src={isDark ? WhiteIcon : BlackIcon} {...props} />;
};

export default RepeatIcon;
