import { lazy, Suspense } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import { ROUTER_PATH } from '@/shared/constants/routes';

import { useAuthStore } from '@/hooks/states/useAuthStore';

import Body from '@/layouts/Body';
import Footer from '@/layouts/Footer';
import Header from '@/layouts/Header';

import AccountList from '@/pages/accounts/AccountList';
import FeedbackList from '@/pages/feedbacks/FeedbackList';
import MeetingList from '@/pages/meetings/MeetingList';
import GoogleLoginSuccess from '@/pages/oauth/google/GoogleLoginSuccess';
import PomodoroTimer from '@/pages/pomodoro/PomodoroTimer';
import ProjectList from '@/pages/projects/ProjectList';
import DailyReportList from '@/pages/reports/DailyReportList';
import MonthlyReportList from '@/pages/reports/MonthlyReportList';
import WeeklyReportList from '@/pages/reports/WeeklyReportList';
import TaskList from '@/pages/tasks/TaskList';
import WorkingTime from '@/pages/working-times/WorkingTime';
import WorkList from '@/pages/works/WorkList';

import Loader from '@/components/Loader';
import WakeLockProvider from '@/components/providers/WakeLockProvider';

const PomodoroStatistics = lazy(() => import('@/pages/pomodoro/PomodoroStatistics'));

const NotFound = lazy(() => import('@/pages/others/NotFound'));
const SignIn = lazy(() => import('@/pages/others/SignIn'));
const SignUp = lazy(() => import('@/pages/others/SignUp'));
const FindPassword = lazy(() => import('@/pages/others/FindPassword'));

const KakaoLoginReady = lazy(() => import('@/pages/oauth/kakao/KakaoLoginReady'));
const KakaoLoginSuccess = lazy(() => import('@/pages/oauth/kakao/KakaoLoginSuccess'));
const KakaoConnectReady = lazy(() => import('@/pages/oauth/kakao/KakaoConnectReady'));
const KakaoConnectSuccess = lazy(() => import('@/pages/oauth/kakao/KakaoConnectSuccess'));

const AccountCreate = lazy(() => import('@/pages/accounts/AccountCreate'));
const AccountUpdate = lazy(() => import('@/pages/accounts/AccountUpdate'));
const AuthorizedGuard = lazy(() => import('@/components/auth/AuthorizedGuard'));
const Sidebar = lazy(() => import('@/components/Sidebar'));
const FeedbackCreate = lazy(() => import('@/pages/feedbacks/FeedbackCreate'));
const MeetingCreate = lazy(() => import('@/pages/meetings/MeetingCreate'));
const MeetingUpdate = lazy(() => import('@/pages/meetings/MeetingUpdate'));
const MyProfile = lazy(() => import('@/pages/members/MyProfile'));
const MyProfileUpdate = lazy(() => import('@/pages/members/MyProfileUpdate'));
const Home = lazy(() => import('@/pages/others/Home'));
const ProjectCreate = lazy(() => import('@/pages/projects/ProjectCreate'));
const ProjectEdit = lazy(() => import('@/pages/projects/ProjectEdit'));
const DailyReportCreate = lazy(() => import('@/pages/reports/DailyReportCreate'));
const DailyReportUpdate = lazy(() => import('@/pages/reports/DailyReportUpdate'));
const TaskCreate = lazy(() => import('@/pages/tasks/TaskCreate'));
const TaskUpdate = lazy(() => import('@/pages/tasks/TaskUpdate'));
const WorkingTimeUpdate = lazy(() => import('@/pages/working-times/WorkingTimeUpdate'));
const WorkCreate = lazy(() => import('@/pages/works/WorkCreate'));

const AppRouterProvider = () => {
  const { accessToken } = useAuthStore();

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <Loader className="fixed w-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
        }
      >
        {accessToken ? <Header /> : null}

        <main className="flex-1 flex items-stretch">
          <Routes>
            <Route path={ROUTER_PATH.NOT_FOUND} element={<NotFound />} />
            <Route path={ROUTER_PATH.SIGN_IN} element={<SignIn />} />
            <Route path={ROUTER_PATH.SIGN_UP} element={<SignUp />} />
            <Route path={ROUTER_PATH.FIND_PASSWORD} element={<FindPassword />} />
            <Route path={ROUTER_PATH.KAKAO_LOGIN_READY} element={<KakaoLoginReady />} />
            <Route path={ROUTER_PATH.KAKAO_LOGIN_SUCCESS} element={<KakaoLoginSuccess />} />
            <Route path={ROUTER_PATH.KAKAO_CONNECT_READY} element={<KakaoConnectReady />} />
            <Route path={ROUTER_PATH.KAKAO_CONNECT_SUCCESS} element={<KakaoConnectSuccess />} />
            <Route path={ROUTER_PATH.GOOGLE_LOGIN_SUCCESS} element={<GoogleLoginSuccess />} />

            <Route
              element={
                <AuthorizedGuard>
                  <Body className="space-y-5">
                    <Outlet />
                  </Body>
                </AuthorizedGuard>
              }
            >
              {/* Others */}
              <Route path={ROUTER_PATH.HOMER} element={<Home />} />

              {/* Reports */}
              <Route path={ROUTER_PATH.DAILY_REPORT_LIST} element={<DailyReportList />} />
              <Route path={ROUTER_PATH.DAILY_REPORT_CREATE} element={<DailyReportCreate />} />
              <Route path={ROUTER_PATH.DAILY_REPORT_UPDATE} element={<DailyReportUpdate />} />
              <Route path={ROUTER_PATH.WEEKLY_REPORT_LIST} element={<WeeklyReportList />} />
              <Route path={ROUTER_PATH.MONTHLY_REPORT_LIST} element={<MonthlyReportList />} />

              {/* Tasks */}
              <Route path={ROUTER_PATH.TASK_LIST} element={<TaskList />} />
              <Route path={ROUTER_PATH.TASK_CREATE} element={<TaskCreate />} />
              <Route path={ROUTER_PATH.TASK_UPDATE} element={<TaskUpdate />} />

              {/* Feedbacks */}
              <Route path={ROUTER_PATH.FEEDBACK_LIST} element={<FeedbackList />} />
              <Route path={ROUTER_PATH.FEEDBACK_CREATE} element={<FeedbackCreate />} />

              {/* Projects */}
              <Route path={ROUTER_PATH.PROJECT_LIST} element={<ProjectList />} />
              <Route path={ROUTER_PATH.PROJECT_CREATE} element={<ProjectCreate />} />
              <Route path={ROUTER_PATH.PROJECT_UPDATE} element={<ProjectEdit />} />

              {/* Works */}
              <Route path={ROUTER_PATH.WORK_LIST} element={<WorkList />} />
              <Route path={ROUTER_PATH.WORK_CREATE} element={<WorkCreate />} />

              {/* Meetings */}
              <Route path={ROUTER_PATH.MEETING_LIST} element={<MeetingList />} />
              <Route path={ROUTER_PATH.MEETING_CREATE} element={<MeetingCreate />} />
              <Route path={ROUTER_PATH.MEETING_UPDATE} element={<MeetingUpdate />} />

              {/* Profile */}
              <Route path={ROUTER_PATH.MY_PROFILE} element={<MyProfile />} />
              <Route path={ROUTER_PATH.MY_PROFILE_UPDATE} element={<MyProfileUpdate />} />

              {/* Working Time */}
              <Route path={ROUTER_PATH.WORKING_TIME} element={<WorkingTime />} />
              <Route path={ROUTER_PATH.WORKING_TIME_UPDATE} element={<WorkingTimeUpdate />} />

              {/* Accounts */}
              <Route path={ROUTER_PATH.ACCOUNT_LIST} element={<AccountList />} />
              <Route path={ROUTER_PATH.ACCOUNT_CREATE} element={<AccountCreate />} />
              <Route path={ROUTER_PATH.ACCOUNT_UPDATE} element={<AccountUpdate />} />

              {/* Pomodoro */}
              <Route
                path={ROUTER_PATH.POMODORO_TIMER}
                element={
                  <WakeLockProvider>
                    <PomodoroTimer />
                  </WakeLockProvider>
                }
              />
              <Route path={ROUTER_PATH.POMODORO_STATISTICS} element={<PomodoroStatistics />} />
            </Route>
          </Routes>
        </main>

        {accessToken ? <Footer /> : null}
        <Sidebar />
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouterProvider;
