import { generate } from '@ce1pers/random-helpers';
import { create } from 'zustand';

export type TPopup = {
  id: string;
  title: string;
  subtitle: string;
  level?: 'info' | 'warning' | 'error';
  timeout?: number;
};

export type TPopupInput = Omit<TPopup, 'id'>;

export type TUsePopupState = {
  popups: TPopup[];
  push: (input: TPopupInput) => void;
  pop: () => void;
  remove: (popupId: string) => void;
};

export const usePopupStore = create<TUsePopupState>((set) => ({
  popups: [],
  push: (input) =>
    set((state) => {
      const { title, subtitle, level = 'info', timeout = 3000 } = input;
      const popups = [{ id: generate(), title, subtitle, level, timeout }, ...state.popups];

      // Clear the popup after timeout.
      window.setTimeout(() => {
        state.pop();
      }, timeout);

      return { popups };
    }),
  pop: () =>
    set((state) => {
      const popups = [...state.popups];

      // Remove last popup.
      popups.pop();

      return { popups };
    }),
  remove: (popupId) =>
    set((state) => {
      const popups = state.popups.filter((popup) => popup.id !== popupId);
      return { popups };
    }),
}));
