import { DateTime } from 'luxon';

export const generateCalendarByDateTime = (date: DateTime) => {
  const startOfMonth = date.startOf('month');
  const endOfMonth = date.endOf('month');
  let initialWeekday = startOfMonth.weekday;
  let initialWeekNumber = [1, 2, 3, 4].includes(startOfMonth.weekday) ? 1 : 0;
  return [...Array(endOfMonth.day)].map((_, i) => {
    const day = i + 1;

    try {
      const weekday = initialWeekday;
      const weekNumber = initialWeekNumber;

      if (initialWeekday >= 7) {
        initialWeekday = 1;
        initialWeekNumber += 1;
      } else {
        initialWeekday += 1;
      }

      return { year: date.year, month: date.month, day, weekday, weekNumber };
    } catch {
      return { year: date.year, month: date.month, day };
    }
  });
};

export const obtainWeekNumberOfMonth = (year: number, month: number) => {
  const parsedDate = DateTime.now().set({ year, month });
  const parsedCalendar = generateCalendarByDateTime(parsedDate);
  const parsedWeekNumbers = parsedCalendar.map(({ weekNumber }) => weekNumber || 1);
  const minWeekNumber = Math.min(...parsedWeekNumbers);
  const maxWeekNumber = Math.max(...parsedWeekNumbers);
  return { minWeekNumber, maxWeekNumber, parsedDate };
};

/**
 * Milliseconds to Days
 */
export const millisecondsToDays = (milliseconds: number) => milliseconds / (1000 * 60 * 60 * 24);

/**
 * Calculate each date difference as percentage.
 */
export const calculateDateDifferencePercentage = (
  startDate: Date,
  endDate: Date,
  specificDate: Date,
): number => {
  try {
    const totalDuration = endDate.getTime() - startDate.getTime();
    const specificDuration = specificDate.getTime() - startDate.getTime();

    if (totalDuration <= 0) {
      // console.warn('종료 날짜는 시작 날짜보다 이후여야 합니다.');
      return 100;
    }

    if (specificDuration > totalDuration) {
      // console.warn('특정 날짜는 종료 날짜보다 이전이어야 합니다.');
      return 100;
    }

    if (specificDuration < 0) {
      console.warn('특정 날짜는 시작 날짜보다 이후여야 합니다.');
      return 0;
    }

    return (specificDuration / totalDuration) * 100;
  } catch (err) {
    console.warn((err as Error).message);
    return 0;
  }
};

export const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
