import { DeleteDailyReportTypeDocument } from '@/shared/api/graphql/reports/documents/delete-daily-report-type.document';
import type {
  TDeleteDailyReportTypeInput,
  TDeleteDailyReportTypeOutput,
} from '@/shared/api/graphql/reports/validators/delete-daily-report-type.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const deleteDailyReportType = async (input: TDeleteDailyReportTypeInput): Promise<void> => {
  const { deleteDailyReportType } = await graphqlApiCaller<TDeleteDailyReportTypeOutput>(
    DeleteDailyReportTypeDocument,
    input,
  );
  if (!deleteDailyReportType.ok) {
    throw new Error(deleteDailyReportType.error.message, {
      cause: deleteDailyReportType.error.code,
    });
  }
};
