import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import MonthlyCalendarData from '@/features/reports/MonthlyCalendarData';
import MonthlyCalendarInput, {
  type OnMonthlyCalendarChangeData,
} from '@/features/reports/MonthlyCalendarInput';
import MonthlyDataFilter from '@/features/reports/MonthlyDataFilter';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { getMonthlyReports } from '@/shared/api/graphql/reports/services/get-monthly-reports.service';
import {
  getMonthlyReportsInputSchema,
  type GetMonthlyReportsInputType,
} from '@/shared/api/graphql/reports/validators/get-monthly.reports.validator';
import { UNKNOWN } from '@/shared/constants';
import { debouncer } from '@/shared/libs/debouncer.lib';

import PieChart from '@/components/charts/PieChart';

type Form = GetMonthlyReportsInputType;

const MonthlyReportList = () => {
  const now = DateTime.now();

  const { watch, setValue } = useForm<Form>({
    defaultValues: {
      year: now.year,
      month: now.month,
    },
    resolver: zodResolver(getMonthlyReportsInputSchema),
  });

  const yearWatch = watch('year');
  const monthWatch = watch('month');

  const [debouncedDate, setDebouncedDate] = useState<Form>({ year: yearWatch, month: monthWatch });
  const [categories, setCategories] = useState<string[]>([]);
  const [projects, setProjects] = useState<string[]>([]);

  useEffect(() => {
    refreshDate();
  }, [yearWatch, monthWatch]);

  async function refreshDate() {
    if (!yearWatch || !monthWatch) return;

    const debounced = await debouncer(() => ({ year: yearWatch, month: monthWatch }));
    setDebouncedDate(debounced);
  }

  //   Queries.
  const { data: monthlyReportsData } = useQuery({
    queryKey: ['monthlyReports', debouncedDate],
    queryFn: () => getMonthlyReports({ year: debouncedDate.year, month: debouncedDate.month }),
  });

  useEffect(() => {
    if (!monthlyReportsData) return;
    setCategories(monthlyReportsData.charts.labels);
  }, [monthlyReportsData]);

  // Handlers.
  const onDateChange = ({ year, month }: OnMonthlyCalendarChangeData) => {
    setValue('year', year);
    setValue('month', month);
  };

  const projectNames =
    monthlyReportsData?.reports.map((report) => report.work?.project?.name ?? UNKNOWN) ?? [];
  const existProjects = projectNames?.filter((project) => !!project);
  const distinctProjects = Array.from(new Set(existProjects));

  return (
    <>
      <section>
        <h1 className="font-bold tracking-wider">월간 보고서</h1>
      </section>

      {/* Calendar input */}
      <section>
        <MonthlyCalendarInput year={yearWatch} month={monthWatch} onChange={onDateChange} />
      </section>

      {/* Chart */}
      <section>
        <PieChart
          labels={monthlyReportsData?.charts.labels ?? []}
          data={monthlyReportsData?.charts.series ?? []}
        />
      </section>

      <section>
        {/* Data filter */}
        {monthlyReportsData?.charts.labels ? (
          <MonthlyDataFilter
            counts={monthlyReportsData.charts.series}
            categories={monthlyReportsData.charts.labels}
            setCategories={setCategories}
            projects={distinctProjects}
            setProjects={setProjects}
          />
        ) : null}
      </section>

      <section>
        {/* Calendar data */}
        <MonthlyCalendarData
          list={monthlyReportsData?.reports}
          categories={categories}
          projects={projects}
        />
      </section>
    </>
  );
};

export default MonthlyReportList;
