import { type GetAccountOutputDataType } from '@/shared/api/graphql/accounts/validators/get-account.validator';

import NoItemsYet from '@/components/NoItemsYet';

import AccountCard from './AccountCard';

type Props = {
  accounts?: GetAccountOutputDataType[];
};

const AccountListItems = ({ accounts }: Props) => {
  return accounts?.length ? (
    <ul className="dynamic-grid">
      {accounts?.map((account) => <AccountCard key={account.id} account={account} />)}
    </ul>
  ) : (
    <NoItemsYet className="text-small h-5">
      계정 정보를 손쉽게 관리하기 위해 먼저 새로운 계정을 생성해보세요!
    </NoItemsYet>
  );
};

export default AccountListItems;
