import { gql } from 'graphql-request';

export const GetDailyReportTypesDocument = gql`
  query dailyReportTypes {
    dailyReportTypes {
      ok
      error {
        code
        message
      }
      data {
        id
        name
        createdAt
      }
    }
  }
`;
