import { gql } from 'graphql-request';

export const DELETE_ACCOUNT_MUTATION = gql`
  mutation deleteAccount($input: DeleteAccountInput!) {
    deleteAccount(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;
