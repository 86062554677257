import HorizontalMenuNav from '@/components/HorizontalMenuNav';

const Footer = () => {
  return (
    <footer className="border-t-pixel sticky bottom-0 w-full bg-white">
      <HorizontalMenuNav className="" />

      {/* <article className="flex-col gap-2 items-center justify-between bg-gray-100 hidden sm:flex sm:flex-row">
        <Link className="heading no-link" to={RoutePaths.Home}>
          <h1>VVorkmon</h1>
        </Link>

        <p className="font-light text-sm">All Rights Reserved</p>
      </article> */}
    </footer>
  );
};

export default Footer;
