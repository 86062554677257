import { useState } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MessageCircleWarning } from 'lucide-react';
import { DateTime } from 'luxon';

import { deleteFeedbackService } from '@/shared/api/graphql/feedbacks/services/delete-feedback.service';
import { TFeedback } from '@/shared/api/graphql/feedbacks/validators/get-feedback.validator';
import { maskingCenter } from '@/shared/helpers/string.helper';

import { useAuthStore } from '@/hooks/states/useAuthStore';
import { usePopupStore } from '@/hooks/states/usePopupStore';

import Button from '@/components/Button';
import ConfirmModal from '@/components/ConfirmModal';
import HorizontalDivider from '@/components/HorizontalDivider';

type Props = {
  feedback: TFeedback;
};

const FeedbackCard = ({ feedback }: Props) => {
  const { me } = useAuthStore();
  const { push } = usePopupStore();
  const queryClient = useQueryClient();

  // States.
  const [deletingFeedbackId, setDeletingFeedbackId] = useState<number | undefined>(undefined);

  const { mutateAsync: deleteFeedbackMutate, isPending: deleteFeedbackIsPending } = useMutation({
    mutationFn: deleteFeedbackService,
    onSuccess: () => {
      push({ title: '피드백 삭제', subtitle: '피드백이 정상적으로 삭제되었습니다.' });

      // 캐시를 삭제합니다.
      queryClient.invalidateQueries({ queryKey: ['feedbacks'] });
    },
    onError: (error) => {
      push({ title: '피드백 삭제', subtitle: error.message, level: 'warning' });
    },
  });

  const parsedCreatedAt = (createdAt: string) => DateTime.fromISO(createdAt).toFormat('yyyy-MM-dd');

  /**
   * 해당 피드백이 내가 작성한 피드백인지 확인하는 함수.
   */
  const isMine = (memberId: number) => {
    if (!me) return false;

    return memberId === me.id;
  };

  /**
   * 피드백 삭제 버튼 클릭 이벤트 핸들러.
   */
  const onFeedbackDeleteClick = async (feedbackId: number) => {
    try {
      setDeletingFeedbackId(feedbackId);
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <>
      <li className="border flex flex-col gap-2 p-2 text-xs border-pixel">
        {/* Header */}
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-2 overflow-hidden flex-1">
            <MessageCircleWarning size={18} />
            <div className="flex-1 text-sm truncate" title={feedback.title}>
              {feedback.title}
            </div>
          </div>

          <span className="text-gray-400 tracking-wider">
            {maskingCenter(feedback.member.name)}
          </span>
        </div>

        {/* Body */}
        <p className="tracking-wider flex-1">{feedback.content}</p>

        <HorizontalDivider />

        {/* Footer */}
        <div className="flex items-center gap-4 justify-between">
          <div className="flex items-center gap-2 flex-wrap">
            <span className="text-gray-500">{parsedCreatedAt(feedback.createdAt)}</span>
            {feedback.isSolved ? (
              <span className="text-green-500">Solved</span>
            ) : (
              <span className="text-red-500">Unsolved</span>
            )}
            <span>{feedback.priority}</span>
            <span>{feedback.status}</span>
          </div>

          {isMine(feedback.member.id) && (
            <Button
              className="text-xs"
              disabled={deleteFeedbackIsPending}
              onClick={() => onFeedbackDeleteClick(feedback.id)}
            >
              삭제
            </Button>
          )}
        </div>
      </li>

      {deletingFeedbackId ? (
        <ConfirmModal
          title="피드백 삭제"
          content="정말 해당 피드백을 삭제하시겠어요?"
          isPending={deleteFeedbackIsPending}
          onConfirm={async () => {
            await deleteFeedbackMutate({ id: deletingFeedbackId });
            setDeletingFeedbackId(undefined);
          }}
          onClose={() => {
            setDeletingFeedbackId(undefined);
          }}
          onCancel={() => {
            setDeletingFeedbackId(undefined);
          }}
        />
      ) : null}
    </>
  );
};

export default FeedbackCard;
