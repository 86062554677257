import { ComponentProps } from 'react';
import { NavLink } from 'react-router-dom';

import MeetingBlackIcon from '@/shared/assets/icons/meeting_icon_black.png';
import MeetingWhiteIcon from '@/shared/assets/icons/meeting_icon_white.png';
import ScheduleBlackIcon from '@/shared/assets/icons/schedule_icon_black.png';
import ScheduleWhiteIcon from '@/shared/assets/icons/schedule_icon_white.png';
import TodoBlackIcon from '@/shared/assets/icons/todo_icon_3_black.png';
import TodoWhiteIcon from '@/shared/assets/icons/todo_icon_3_white.png';
import { ROUTER_PATH } from '@/shared/constants/routes';
import { cn } from '@/shared/libs/style.lib';

import { useAuthStore } from '@/hooks/states/useAuthStore';
import { useDarkModeStore } from '@/hooks/states/useDarkMode';

import Avatar from '@/components/Avatar';
import UsersIcon from '@/components/icons/UsersIcon';

type Props = ComponentProps<'nav'>;

// Common class name of each of them.

const routes = [
  {
    title: '리포트',
    to: ROUTER_PATH.DAILY_REPORT_LIST,
    icon: { black: ScheduleBlackIcon, white: ScheduleWhiteIcon },
  },
  {
    title: '할일',
    to: ROUTER_PATH.TASK_LIST,
    icon: { black: TodoBlackIcon, white: TodoWhiteIcon },
  },
  {
    title: '회의',
    to: ROUTER_PATH.MEETING_LIST,
    icon: { black: MeetingBlackIcon, white: MeetingWhiteIcon },
  },
] as const;

const HorizontalMenuNav = ({ ...rest }: Props) => {
  const { isDark } = useDarkModeStore();

  const { me } = useAuthStore();

  return (
    <nav className="w-full" {...rest}>
      <ul className="grid grid-cols-4 divide-x divide-gray-300">
        {routes.map((route) => (
          <li title={route.title} key={route.to}>
            <NavLink
              to={route.to}
              className={({ isActive }) =>
                cn('footer-nav-link group', {
                  'animate-pulse active': isActive,
                })
              }
            >
              <img
                className="size-4 bg-transparent"
                src={isDark ? route.icon.white : route.icon.black}
                alt={route.title}
                loading="lazy"
              />

              <span className="text-[8px] bg-inherit transition-none">{route.title}</span>
            </NavLink>
          </li>
        ))}

        <li title="내 정보">
          <NavLink
            to={ROUTER_PATH.MY_PROFILE}
            className={({ isActive }) => cn('footer-nav-link group', { 'animate-pulse': isActive })}
          >
            {me?.avatarUrl ? (
              <Avatar className="size-4" url={me.avatarUrl} />
            ) : (
              <UsersIcon className={cn('size-4 transition')} />
            )}

            <span className="text-[8px] bg-inherit transition-none">내정보</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default HorizontalMenuNav;
