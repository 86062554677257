import { useEffect } from 'react';

type Props = {
  onChange?: (x: number, y: number) => void;
};

const useMousePosition = ({ onChange }: Props) => {
  // Hook: Set event listeners.
  useEffect(() => {
    document.addEventListener('mousemove', onMoveMouse);

    return () => {
      document.removeEventListener('mousemove', onMoveMouse);
    };
  }, []);

  /**
   * Set direction(Horizontal, Vertical) values.
   */
  function onMoveMouse(event: MouseEvent) {
    const { x, y } = event;

    onChange?.(x, y);
  }
};

export default useMousePosition;
