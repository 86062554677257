import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createDailyReportType } from '@/shared/api/graphql/reports/services/create-daily-report-type.service';
import { deleteDailyReportType } from '@/shared/api/graphql/reports/services/delete-daily-report-type.service';
import { getDailyReportTypes } from '@/shared/api/graphql/reports/services/get-daily-report-types.service';
import {
  CreateDailyReportTypeInputSchema,
  TCreateDailyReportTypeInput,
} from '@/shared/api/graphql/reports/validators/create-daily-report-type.validator';

import { usePopupStore } from '@/hooks/states/usePopupStore';

import Button from '@/components/Button';
import ConfirmModal from '@/components/ConfirmModal';
import TextInput from '@/components/inputs/TextInput';
import Loader from '@/components/Loader';
import PillDelete from '@/components/PillDelete';
import Portal from '@/components/Portal';

const DailyReportTypes = () => {
  const { push } = usePopupStore();
  const queryClient = useQueryClient();

  const [selectedDailyReportType, setSelectedDailyReportType] = useState<number | undefined>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<TCreateDailyReportTypeInput>({
    resolver: zodResolver(CreateDailyReportTypeInputSchema),
  });

  // Queries
  const { data: dailyReportTypes, isLoading: isDailyReportTypesLoading } = useQuery({
    queryKey: ['dailyReportTypes'],
    queryFn: getDailyReportTypes,
  });

  // Mutations.
  // Create daily report type mutation.
  const { mutateAsync: createDailyReportTypeMutate, isPending: isCreateDailyReportTypePending } =
    useMutation({
      mutationFn: createDailyReportType,
      onSuccess: () => {
        queryClient.invalidateQueries();
        push({ title: '생성', subtitle: '정상적으로 생성되었습니다.' });
        reset();
      },
      onError: (error) => {
        push({ title: '생성', subtitle: error.message, level: 'error' });
      },
    });

  // Delete daily report type mutation.
  const { mutateAsync: deleteDailyReportTypeMutate, isPending: isDeleteDailyReportTypePending } =
    useMutation({
      mutationFn: deleteDailyReportType,
      onSuccess: () => {
        queryClient.invalidateQueries();
        push({ title: '삭제', subtitle: '정상적으로 삭제되었습니다.' });
      },
      onError: (error) => {
        push({ title: '삭제', subtitle: error.message, level: 'error' });
      },
    });

  return (
    <>
      <div className="space-y-3">
        {/* Form */}
        <form
          className="flex flex-col gap-2"
          onSubmit={handleSubmit((form) => createDailyReportTypeMutate(form))}
        >
          <div className="flex flex-col sm:flex-row items-center justify-between gap-3">
            <TextInput
              inputWrapperClassName="w-full"
              className="w-full px-2 py-1"
              register={register('name')}
              placeholder="기획 회의"
            />

            <Button
              className="w-full sm:w-20"
              disabled={isSubmitting || isCreateDailyReportTypePending}
            >
              추가
            </Button>
          </div>
          {errors.name?.message ? <p className="error">{errors.name.message}</p> : null}
        </form>

        {/* List */}
        <section>
          {isDailyReportTypesLoading ? (
            <Loader />
          ) : dailyReportTypes?.length ? (
            <ul className="flex items-center gap-2 flex-wrap">
              {dailyReportTypes.map((item) => (
                <li key={item.id} className="flex items-center gap-2">
                  <PillDelete
                    disabled={isDeleteDailyReportTypePending}
                    onClick={() => setSelectedDailyReportType(item.id)}
                  >
                    {item.name}
                  </PillDelete>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-small text-blue-500 animate-pulse">
              일일 보고서를 더 디테일하게 관리하기 위해, 보고서 타입을 먼저 생성해보세요!
            </p>
          )}
        </section>
      </div>

      <Portal portalId="modal">
        {selectedDailyReportType ? (
          <ConfirmModal
            title="리포트 타입 삭제"
            content="정말 해당 리포트 타입을 삭제하시겠어요?"
            isPending={isDeleteDailyReportTypePending}
            onConfirm={async () => {
              await deleteDailyReportTypeMutate({ id: selectedDailyReportType });
              setSelectedDailyReportType(undefined);
            }}
            onClose={() => setSelectedDailyReportType(undefined)}
            onCancel={() => setSelectedDailyReportType(undefined)}
          />
        ) : null}
      </Portal>
    </>
  );
};

export default DailyReportTypes;
