import { GET_MEETINGS_QUERY } from '@/shared/api/graphql/meetings/documents/get-meetings.document';
import type {
  GetMeetingsOutputDataType,
  GetMeetingsOutputType,
} from '@/shared/api/graphql/meetings/validators/get-meetings.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const getMeetings = async (): Promise<GetMeetingsOutputDataType[]> => {
  const { meetings } = await graphqlApiCaller<GetMeetingsOutputType>(GET_MEETINGS_QUERY);

  if (!meetings.ok) {
    throw new Error(meetings.error.message, { cause: meetings.error.code });
  }
  return meetings.data;
};
