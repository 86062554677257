import { ComponentProps, PropsWithChildren } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { cn } from '@/shared/libs/style.lib';

import ArrowIcon from '../icons/ArrowIcon';

export type SelectInputOption = {
  label: string;
  value: string | number;
};

type Props = PropsWithChildren &
  ComponentProps<'select'> & {
    register?: UseFormRegisterReturn;
    options: SelectInputOption[];
    withNoneOption?: boolean;
    error?: string;
    wrapperClassName?: string;
  };

const SelectInput = ({
  className,
  wrapperClassName,
  id,
  options,
  register,
  error,
  withNoneOption = false,
  disabled,
  children,
  ...rest
}: Props) => {
  return (
    <>
      <label className="text-xs text-gray-500" htmlFor={id}>
        {children}
      </label>
      <div
        className={cn(
          'group flex gap-2 items-center border-pixel px-3 py-1.5',
          {
            disabled,
          },
          wrapperClassName,
        )}
      >
        <select
          className={cn(
            'cursor-pointer w-full truncate disabled:cursor-not-allowed group-disabled:bg-red-500',
            className,
          )}
          id={id}
          disabled={disabled}
          {...rest}
          {...register}
        >
          {withNoneOption && <option value="">선택 안함</option>}
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <ArrowIcon className="size-2 rotate-90" />
      </div>

      {error ? <p className="error">{error}</p> : null}
    </>
  );
};

export default SelectInput;
