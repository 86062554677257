import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getFeedbacks } from '@/shared/api/graphql/feedbacks/services/get-feedbacks.service';
import { ROUTER_PATH } from '@/shared/constants/routes';

import Button from '@/components/Button';
import NoItemsYet from '@/components/NoItemsYet';

import FeedbackCard from '@/features/feedbacks/FeedbackCard';

const FeedbackList = () => {
  const navigate = useNavigate();
  const { data: feedbacksData } = useQuery({
    queryKey: ['feedbacks'],
    queryFn: () => getFeedbacks({ onlyOwn: false }),
  });

  const onNewClick = () => {
    navigate(ROUTER_PATH.FEEDBACK_CREATE);
  };

  return (
    <article className="space-y-5">
      <section className="flex items-center justify-between gap-10">
        <div className="flex flex-col gap-2">
          <h1 className="font-bold tracking-wider">피드백 목록</h1>
          <p className="text-xs text-gray-500">
            피드백은 최대 2개 까지 생성할 수 있으며, 기존에 생성된 피드백이 해결되어야 다시 생성할
            수 있습니다.
          </p>
        </div>

        <Button onClick={onNewClick}>추가</Button>
      </section>

      {feedbacksData?.length ? (
        <ul className="dynamic-grid">
          {feedbacksData.map((feedback) => (
            <FeedbackCard key={feedback.id} feedback={feedback} />
          ))}
        </ul>
      ) : (
        <NoItemsYet>작성된 피드백이 없습니다.</NoItemsYet>
      )}
    </article>
  );
};

export default FeedbackList;
