import { gql } from 'graphql-request';

export const DELETE_WORK_MUTATION = gql`
  mutation deleteWork($input: DeleteWorkInput!) {
    deleteWork(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;
