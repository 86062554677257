import { ComponentProps, useEffect } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { DateTime } from 'luxon';

import {
  createDailyReportFormSchema,
  CreateDailyReportFormType,
} from '@/shared/api/graphql/reports/validators/create-daily-report.validator';
import { TGetDailyReportTypesOutputData } from '@/shared/api/graphql/reports/validators/get-daily-report-types.validator';
import type { GetDailyReportType } from '@/shared/api/graphql/reports/validators/get-daily-report.validator';
import { GetWorksOutputDataType } from '@/shared/api/graphql/works/validators/get-works.validator';
import { UNKNOWN } from '@/shared/constants';
import { cn } from '@/shared/libs/style.lib';

import Button from '@/components/Button';
import HelpText from '@/components/HelpText';
import SaveIcon from '@/components/icons/SaveIcon';
import SelectInput from '@/components/inputs/SelectInput';
import TextAreaInput from '@/components/inputs/TextAreaInput';
import TextInput from '@/components/inputs/TextInput';
import Loader from '@/components/Loader';

type Props = ComponentProps<'form'> & {
  defaultValues?: Partial<CreateDailyReportFormType>;
  works?: GetWorksOutputDataType;
  dailyReportTypes?: TGetDailyReportTypesOutputData;
  dailyReport?: GetDailyReportType | null;
  isLoading?: boolean;
  disabled?: boolean;
  index?: number;

  onSubmitValid: (form: CreateDailyReportFormType, index?: number) => Promise<void>;
};

const DailyReportEditForm = ({
  className,
  defaultValues,
  works,
  dailyReportTypes,
  dailyReport,
  isLoading,
  disabled,
  index,
  onSubmitValid,
  ...rest
}: Props) => {
  const now = DateTime.now();

  // Queries.

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<CreateDailyReportFormType>({
    defaultValues: {
      ...defaultValues,
      workId: '',
      reportTypeId: '',
      date: defaultValues?.date ?? now.toFormat('yyyy-MM-dd'),
      startTime: defaultValues?.startTime ?? now.toFormat('HH:mm'),
      endTime: defaultValues?.endTime
        ? defaultValues.endTime
        : defaultValues?.startTime
          ? DateTime.fromFormat(defaultValues.startTime, 'HH:mm')
              .plus({ minutes: 30 })
              .toFormat('HH:mm')
          : now.plus({ minutes: 30 }).toFormat('HH:mm'),
    },
    resolver: zodResolver(createDailyReportFormSchema),
  });

  // Functions.

  const onPreInvalidSubmit = (errors: FieldErrors<CreateDailyReportFormType>) => {
    console.warn(errors);
  };

  const onPreSubmitValid = (form: CreateDailyReportFormType) => {
    onSubmitValid(form, index);
  };

  // Effects.

  useEffect(() => {
    setDefaults();
  }, [dailyReport]);

  // Functions.

  function setDefaults() {
    // Is update mode?
    if (dailyReport) setUpdateModeDefaults(dailyReport);
    // Is create mode?
    else setCreateModeDefaults();
  }

  function setCreateModeDefaults() {
    // Set default work value.
    const firstWork = works?.at(0);
    if (firstWork?.id) setValue('workId', firstWork.id + '');

    // Set default report type value.
    const firstReportType = dailyReportTypes?.at(0);

    if (firstReportType?.id) setValue('reportTypeId', firstReportType.id + '');
  }

  function setUpdateModeDefaults(report: GetDailyReportType) {
    const { content, date, memo, startTime, endTime, reportType, work } = report;

    console.log(reportType?.id + '');
    setValue('content', content);
    setValue('date', date);
    setValue('startTime', startTime);
    setValue('endTime', endTime);
    setValue('reportTypeId', reportType ? reportType.id + '' : '');
    setValue('workId', work ? work.id + '' : '');
    if (memo) setValue('memo', memo);
  }

  if (isLoading) return <Loader />;

  return (
    <form
      className={cn('flex flex-col gap-3', className)}
      onSubmit={handleSubmit(onPreSubmitValid, onPreInvalidSubmit)}
      {...rest}
    >
      {/* Works */}
      {works?.length ? (
        <SelectInput
          id="input-work-id"
          register={register('workId')}
          options={works.map((work) => ({
            label: `${work.project?.name ?? UNKNOWN}:${work.name}`,
            value: work.id,
          }))}
          error={errors.workId?.message}
          withNoneOption
        >
          <div className="text-xs text-gray-500 flex items-center justify-between gap-2">
            <span className="text-xs text-gray-500 shrink-0">작업</span>
            <HelpText>리포트를 각각의 작업으로 그룹화 할 수 있어요.</HelpText>
          </div>
        </SelectInput>
      ) : null}

      {/* Daily report type */}
      {dailyReportTypes?.length ? (
        <SelectInput
          id="input-daily-report-type-id"
          register={register('reportTypeId')}
          options={dailyReportTypes.map((type) => ({
            label: String(type.name),
            value: type.id,
          }))}
          error={errors.reportTypeId?.message}
          withNoneOption
        >
          <div className="text-xs text-gray-500 flex items-center justify-between gap-2">
            <span className="text-xs text-gray-500 shrink-0">일일 보고 타입</span>
            <HelpText>리포트를 각각의 타입으로 분류할 수 있어요.</HelpText>
          </div>
        </SelectInput>
      ) : null}

      {/* Date */}
      <TextInput
        register={register('date')}
        error={errors.date?.message}
        id="input-date"
        placeholder="2023-12-20"
        type="date"
      >
        <div className="flex justify-between items-center gap-2">
          <span className="text-xs text-gray-500 shrink-0">날짜</span>
        </div>
      </TextInput>

      <div className="space-y-2">
        <div className="flex justify-between items-center gap-2">
          <span className="text-xs text-gray-500 shrink-0">시작 시간</span>
        </div>

        <div className="flex flex-col items-center justify-between gap-2 xs:flex-row">
          {/* Start time */}
          <TextInput
            inputWrapperClassName="w-full"
            register={register('startTime')}
            error={errors.startTime?.message}
            id="input-start-time"
            placeholder="10:30"
            type="time"
          />

          <span>~</span>

          {/* End time */}
          <TextInput
            inputWrapperClassName="w-full"
            register={register('endTime')}
            error={errors.endTime?.message}
            id="input-end-time"
            placeholder="11:00"
            type="time"
          />
        </div>
      </div>

      {/* Content */}
      <TextInput
        register={register('content')}
        error={errors.content?.message}
        id="input-content"
        placeholder="Buy keyboard"
        autoFocus
      >
        <div className="flex justify-between items-center gap-2">
          <p className="text-xs text-gray-500 shrink-0">내용</p>
          <HelpText>진행한 작업 내용을 간단히 입력해요.</HelpText>
        </div>
      </TextInput>

      {/* Memo */}
      <TextAreaInput
        register={register('memo')}
        watch={watch}
        maxLength={300}
        error={errors.memo?.message}
        id="input-memo"
        placeholder="From E-mart"
        rows={4}
      >
        <div className="flex justify-between items-center gap-2">
          <p className="text-xs text-gray-500 shrink-0">메모</p>
        </div>
      </TextAreaInput>

      <Button className="gap-2" disabled={disabled}>
        <SaveIcon />
      </Button>
    </form>
  );
};

export default DailyReportEditForm;
