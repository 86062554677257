import { ComponentProps } from 'react';

import { cn } from '@/shared/libs/style.lib';

type Props = ComponentProps<'div'>;

const NoItemsYet = ({ className, children }: Props) => {
  return (
    <div className={cn('flex items-center justify-center h-32', className)}>
      <p className="animate-pulse">{children}</p>
    </div>
  );
};

export default NoItemsYet;
