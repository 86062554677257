import React, { useRef } from 'react';

type Props = {
  onSubmit?: () => void;
};

function useSubmitShortcut<T = unknown>({ onSubmit }: Props) {
  const isCtrlKeyPressed = useRef(false);

  /**
   * 'Textarea key down' event handler.
   */
  const onKeyDown = (event: React.KeyboardEvent<T>) => {
    switch (event.key) {
      case 'Control':
      case 'Meta':
        isCtrlKeyPressed.current = true;
        break;

      case 'Enter':
        if (isCtrlKeyPressed.current) onSubmit?.();
        break;
    }
  };

  /**
   * 'Textarea key up' event handler.
   */
  const onKeyUp = (event: React.KeyboardEvent<T>) => {
    switch (event.key) {
      case 'Meta':
        isCtrlKeyPressed.current = false;
        break;
    }
  };

  return {
    onKeyDown,
    onKeyUp,
  };
}

export default useSubmitShortcut;
