import { CreateDailyReportDocument } from '@/shared/api/graphql/reports/documents/create-daily-report.document';
import type {
  CreateDailyReportInputType,
  CreateDailyReportOutputType,
} from '@/shared/api/graphql/reports/validators/create-daily-report.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const createDailyReport = async (input: CreateDailyReportInputType): Promise<void> => {
  const { createDailyReport } = await graphqlApiCaller<CreateDailyReportOutputType>(
    CreateDailyReportDocument,
    input,
  );
  if (!createDailyReport.ok) {
    throw new Error(createDailyReport.error.message, {
      cause: createDailyReport.error.code,
    });
  }
};
