import { gql } from 'graphql-request';

export const GetDailyReportsDocument = gql`
  query dailyReports($input: GetDailyReportsInput!) {
    dailyReports(input: $input) {
      ok
      error {
        code
        message
      }
      data {
        id
        content
        date
        time
        startTime
        endTime
        memo
        createdAt
        reportType {
          id
          name
        }
        work {
          id
          name
          description
          project {
            id
            name
            description
          }
        }
      }
    }
  }
`;
