import { graphqlApiCaller } from '@/shared/helpers/api.helper';

import { GET_WORKS_QUERY } from '../documents/get-works.document';
import { GetWorksOutputDataType, GetWorksOutputType } from '../validators/get-works.validator';

export const getWorks = async (): Promise<GetWorksOutputDataType> => {
  const { works } = await graphqlApiCaller<GetWorksOutputType>(GET_WORKS_QUERY);
  if (!works.ok) {
    throw new Error(works.error.message, {
      cause: works.error.code,
    });
  }

  return works.data;
};
