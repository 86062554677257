import { GET_FEEDBACKS_QUERY } from '@/shared/api/graphql/feedbacks/documents/get-feedbacks.document';
import { TFeedback } from '@/shared/api/graphql/feedbacks/validators/get-feedback.validator';
import {
  TGetFeedbacksInput,
  TGetFeedbacksOutput,
} from '@/shared/api/graphql/feedbacks/validators/get-feedbacks.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const getFeedbacks = async (input: TGetFeedbacksInput): Promise<TFeedback[]> => {
  const { feedbacks } = await graphqlApiCaller<TGetFeedbacksOutput>(GET_FEEDBACKS_QUERY, input);
  if (!feedbacks.ok) {
    throw new Error(feedbacks.error.message, { cause: feedbacks.error.code });
  }
  return feedbacks.data;
};
