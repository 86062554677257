import {
  DeleteFeedbackInput,
  DeleteFeedbackOutput,
} from '@/shared/api/graphql/feedbacks/validators/delete-feedback.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

import { DELETE_FEEDBACK_MUTATION } from '../documents/delete-feedback.document';

export const deleteFeedbackService = async (input: DeleteFeedbackInput) => {
  const { deleteFeedback } = await graphqlApiCaller<DeleteFeedbackOutput>(
    DELETE_FEEDBACK_MUTATION,
    input,
  );
  if (!deleteFeedback.ok) {
    throw new Error(deleteFeedback.error.message, {
      cause: deleteFeedback.error.code,
    });
  }
};
