import { gql } from 'graphql-request';

export const GetWeeklyReportsDocument = gql`
  query weeklyReports($input: GetWeeklyReportsInput!) {
    weeklyReports(input: $input) {
      ok
      error {
        code
        message
      }
      data {
        reports {
          id
          content
          date
          time
          memo
          createdAt
          reportType {
            id
            name
          }
          work {
            name
            project {
              name
            }
          }
        }
        statistics {
          labels
          series
        }
      }
    }
  }
`;
