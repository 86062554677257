import { useNavigate } from 'react-router-dom';

import { type GetDailyReportType } from '@/shared/api/graphql/reports/validators/get-daily-report.validator';
import { UNKNOWN } from '@/shared/constants';
import { ROUTER_PATH } from '@/shared/constants/routes';

import WeeklyReportItem from '@/features/reports/WeeklyReportItem';

type Props = {
  list?: GetDailyReportType[];
  categories?: string[];
  projects?: string[];
};

const MonthlyCalendarData = ({ list, categories, projects }: Props) => {
  const navigate = useNavigate();

  const onItemClick = (reportId: number) => {
    navigate(`${ROUTER_PATH.DAILY_REPORT_UPDATE}?id=${reportId}`);
  };

  // Filter list by project list.
  const filteredByProjects =
    list?.filter((item) => projects?.includes(item.work?.project?.name ?? UNKNOWN)) ?? [];

  // Filter list by filtered projects.
  const filteredByCategories =
    filteredByProjects?.filter((item) => categories?.includes(item.reportType?.name + '')) ?? [];

  if (!list?.length) {
    return (
      <p className="text-small">
        Create a new daily report item to management your tasks be easier.
      </p>
    );
  }

  if (!filteredByCategories.length) {
    return <p className="text-small">Choose any filter item to display items.</p>;
  }

  return (
    <ul className="space-y-2">
      {filteredByCategories.map((report) => (
        <WeeklyReportItem
          key={report.id}
          report={report}
          onItemClick={() => onItemClick(report.id)}
        />
      ))}
    </ul>
  );
};

export default MonthlyCalendarData;
