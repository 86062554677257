import { useEffect } from 'react';

import AppRouterProvider from '@/app/AppRouterProvider';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { getWorkingTime, setWorkingTime } from '@/shared/api/graphql/members/services';
import { getMe } from '@/shared/api/graphql/members/services/me.service';
import { cn } from '@/shared/libs/style.lib';
import { initWindow } from '@/shared/scripts';

import { useAuthStore } from '@/hooks/states/useAuthStore';
import { useDarkModeStore } from '@/hooks/states/useDarkMode';
import { usePopupStore } from '@/hooks/states/usePopupStore';

import PopupList from '@/components/popups/PopupList';
import Portal from '@/components/Portal';

function App() {
  const otherPositionElements = ['#drawer', '#modal', '#popup'];

  const { isDark } = useDarkModeStore();
  const { accessToken, setMe } = useAuthStore();

  const { push } = usePopupStore();

  // Me query.
  const { data: meData } = useQuery({
    queryKey: ['me'],
    queryFn: getMe,
    enabled: !!accessToken,
  });

  // Working time query.
  const { data: workingTimeData } = useQuery({
    queryKey: ['workingTime'],
    queryFn: getWorkingTime,
    enabled: !!accessToken,
  });

  // Working time update mutation.
  const { mutateAsync: setWorkingTimeMutate, isPending: setWorkingTimeIsPending } = useMutation({
    mutationFn: setWorkingTime,
    onSuccess: () => {
      push({ title: '근무 시간 변경', subtitle: '정상적으로 변경되었습니다' });
    },
    onError: (error) => {
      push({ title: '근무 시간 변경', subtitle: error.message, level: 'error' });
    },
  });

  /**
   * Initial window object setting.
   */
  useEffect(() => {
    initWindow();
  }, []);

  /**
   * 'Me' data event handler.
   */
  useEffect(() => {
    if (!accessToken) return;
    if (!meData) return;

    setMe(meData);
  }, [accessToken, meData]);

  /**
   * 'Dark toggle' change event handler.
   */
  useEffect(() => {
    otherPositionElements.forEach((elementId) => {
      const element = document.body.querySelector(elementId);

      if (isDark) element?.classList.add('dark');
      else element?.classList.remove('dark');
    });
  }, [isDark]);

  /**
   * When is auto refresh enabled.
   */
  useEffect(() => {
    workingTimeAutoRefresh();
  }, [workingTimeData?.isAutoRefresh]);

  // Functions.

  function workingTimeAutoRefresh() {
    // Is already requested?
    if (setWorkingTimeIsPending) return;

    // Is auto refresh disabled?
    if (!workingTimeData?.isAutoRefresh) return;

    // Is already set auto refresh?
    const updatedAtDateTime = DateTime.fromISO(workingTimeData.updatedAt);
    const comparedWithNow = updatedAtDateTime.endOf('day').diffNow().toMillis();
    const isTodayUpdated = comparedWithNow > 0;
    if (isTodayUpdated) return;

    // Reset working time automatically by now time.
    const {
      breakTime,
      totalTime,
      isAutoRefresh,
      autoRefreshStartTime = '00:00',
      autoRefreshEndTime = '24:00',
      isDisplayEndTime,
    } = workingTimeData;

    // Compute auto refresh period.
    const startDateTime = DateTime.fromFormat(autoRefreshStartTime, 'HH:mm');
    const startDateTimeNow = DateTime.now()
      .set({
        hour: startDateTime.hour,
        minute: startDateTime.minute,
      })
      .diffNow()
      .toMillis();

    const endDateTime = DateTime.fromFormat(autoRefreshEndTime, 'HH:mm');
    const endDateTimeNow = DateTime.now()
      .set({
        hour: endDateTime.hour,
        minute: endDateTime.minute,
      })
      .diffNow()
      .toMillis();

    // Is included between start and end time?
    if (startDateTimeNow <= 0 && endDateTimeNow >= 0) {
      setWorkingTimeMutate({
        startTime: DateTime.now().toFormat('HH:mm'),
        breakTime,
        totalTime,
        isAutoRefresh,
        isDisplayEndTime,
      });
    }
  }

  return (
    <>
      <div className={cn('min-h-screen flex flex-col', { dark: isDark })}>
        <AppRouterProvider />
      </div>

      <Portal portalId="popup">
        <PopupList />
      </Portal>
    </>
  );
}

export default App;
