import { GET_MONTHLY_REPORTS_QUERY } from '@/shared/api/graphql/reports/documents/get-monthly-reports.document';
import type {
  GetMonthlyReportsInputType,
  GetMonthlyReportsOutputType,
} from '@/shared/api/graphql/reports/validators/get-monthly.reports.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const getMonthlyReports = async (input: GetMonthlyReportsInputType) => {
  const { monthlyReports } = await graphqlApiCaller<GetMonthlyReportsOutputType>(
    GET_MONTHLY_REPORTS_QUERY,
    input,
  );

  if (!monthlyReports.ok) {
    throw new Error(monthlyReports.error.message, {
      cause: monthlyReports.error.code,
    });
  }

  return monthlyReports.data;
};
