import * as React from 'react';
import { createPortal } from 'react-dom';

interface Props {
  children: React.ReactNode;
  portalId: 'popup' | 'drawer' | 'modal';
}

const Portal = ({ children, portalId }: Props) => {
  const element = document.getElementById(portalId);

  return element ? <>{createPortal(children, element)}</> : null;
};

export default Portal;
