import { useEffect, useRef } from 'react';

import * as Chart from 'chart.xkcd';

import { COLORS, UNKNOWN } from '@/shared/constants';

import { useDarkModeStore } from '@/hooks/states/useDarkMode';

type Props = {
  title?: string;
  labels: string[];
  data: number[];
};

const PieChart = ({ title, labels, data }: Props) => {
  const svgRef = useRef<SVGSVGElement>(null);

  const parsedLabels = labels.map((label) => (label === 'undefined' ? UNKNOWN : label));

  // Hook: Dark mode
  const { isDark } = useDarkModeStore();

  // Hook: Drawing chart.
  useEffect(() => {
    drawChart();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [title, labels, data, isDark]);

  // Functions.

  /**
   * When resize browser event handler.
   */
  function onResize() {
    drawChart();
  }

  /**
   * Draw chart by props.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function drawChart() {
    if (!svgRef.current) return;

    new Chart.Pie(svgRef.current, {
      title,
      data: {
        labels: parsedLabels,
        datasets: [{ data }],
      },
      options: {
        innerRadius: 0.5,
        legendPosition: Chart.config.positionType.upRight,
        strokeColor: isDark ? COLORS.SEXY_WHITE : COLORS.SEXY_BLACK,
        backgroundColor: isDark ? COLORS.SEXY_BLACK : COLORS.SEXY_WHITE,
      },
    });

    const legend = svgRef.current.querySelector('g:last-child');
    legend?.setAttribute('transform', 'translate(-12, 30)');
  }

  return (
    <div className="flex items-center justify-center">
      {labels.length ? (
        <svg ref={svgRef} className="scale-90" />
      ) : (
        <small className="tracking-wider text-gray-500 animate-pulse">
          표시할 데이터가 없습니다.
        </small>
      )}
    </div>
  );
};

export default PieChart;
