import { GetDailyReportTypesDocument } from '@/shared/api/graphql/reports/documents/get-daily-report-types.document';
import type {
  TGetDailyReportTypesOutput,
  TGetDailyReportTypesOutputData,
} from '@/shared/api/graphql/reports/validators/get-daily-report-types.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const getDailyReportTypes = async (): Promise<TGetDailyReportTypesOutputData> => {
  const { dailyReportTypes } = await graphqlApiCaller<TGetDailyReportTypesOutput>(
    GetDailyReportTypesDocument,
  );
  if (!dailyReportTypes.ok) {
    throw new Error(dailyReportTypes.error.message, {
      cause: dailyReportTypes.error.code,
    });
  }
  return dailyReportTypes.data;
};
