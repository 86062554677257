import { gql } from 'graphql-request';

export const DeleteDailyReportTypeDocument = gql`
  mutation deleteDailyReportType($input: DeleteDailyReportTypeInput!) {
    deleteDailyReportType(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;
