import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type TUseDarkModeState = {
  isDark: boolean;
  toggle: () => void;
  setIsDark: (mode: boolean) => void;
};

export const useDarkModeStore = create<TUseDarkModeState>()(
  persist(
    (set) => ({
      isDark: false,
      toggle: () => set((state) => ({ isDark: !state.isDark })),
      setIsDark: (mode) => set({ isDark: mode }),
    }),
    {
      name: 'vvorkmon-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
