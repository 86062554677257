import { SyntheticEvent, useEffect, useState } from 'react';

import { Filter, FoldVertical, UnfoldVertical } from 'lucide-react';

import { cn } from '@/shared/libs/style.lib';

type Props = {
  counts?: number[];

  categories: string[];
  setCategories?: React.Dispatch<React.SetStateAction<string[]>>;

  projects: string[];
  setProjects?: React.Dispatch<React.SetStateAction<string[]>>;
};

const MonthlyDataFilter = ({ categories, counts, projects, setCategories, setProjects }: Props) => {
  const selectedClassName = 'bg-gray-400 text-white';

  // Category states.
  const [isAllCategoriesSelected, setIsAllCategoriesSelected] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(categories);

  // Project states.
  const [isAllProjectsSelected, setIsAllProjectsSelected] = useState(true);
  const [selectedProjects, setSelectedProjects] = useState<string[]>(projects);

  // Filter fold status state.
  const [isFilterOpen, setIsFilterOpen] = useState(true);

  /**
   * Each 'category' item click event handler.
   */
  const onCategoryClick = (categoryName: string) => {
    setSelectedCategories((current) => {
      // 필터를 추가하거나 제거하는 새로운 배열 생성
      const filtered = current.includes(categoryName)
        ? current.filter((selectedFilter) => selectedFilter !== categoryName)
        : [...current, categoryName];

      // 모든 필터가 선택되었는지 확인
      setIsAllCategoriesSelected(filtered.length === categories.length);

      return filtered;
    });
  };

  /**
   * Checking the 'category' name is selected?
   */
  const isSelectedCategory = (categoryName: string) => {
    return selectedCategories.includes(categoryName);
  };

  /**
   * All 'category' button click event handler.
   */
  const onAllCategoriesClick = () => {
    setIsAllCategoriesSelected((current) => !current);
    setSelectedCategories((current) => (current.length !== categories.length ? categories : []));
  };

  /**
   * When 'category' changed.
   */
  useEffect(() => {
    setCategories?.(selectedCategories);
  }, [selectedCategories]);

  /**
   * Each 'project' item click event handler.
   */
  const onProjectClick = (projectName: string) => {
    setSelectedProjects((current) => {
      // 필터를 추가하거나 제거하는 새로운 배열 생성
      const filtered = current.includes(projectName)
        ? current.filter((selectedFilter) => selectedFilter !== projectName)
        : [...current, projectName];

      // 모든 필터가 선택되었는지 확인
      setIsAllProjectsSelected(filtered.length === projects.length);

      return filtered;
    });
  };

  /**
   * Checking the 'project' name is selected?
   */
  const isSelectedProject = (projectName: string) => selectedProjects.includes(projectName);

  /**
   * All 'project' button click event handler.
   */
  const onAllProjectsClick = () => {
    setIsAllProjectsSelected((current) => !current);
    setSelectedProjects((current) => (current.length !== projects.length ? projects : []));
  };

  /**
   * When 'product' changed.
   */
  useEffect(() => {
    setProjects?.(selectedProjects);
  }, [selectedProjects]);

  /**
   * When fold toggle status changed.
   */
  const onFoldToggle = (e: SyntheticEvent<HTMLDetailsElement>) => {
    setIsFilterOpen(e.currentTarget.open);
  };

  return (
    <details className="space-y-2" open={isFilterOpen} onToggle={onFoldToggle}>
      <summary className="group flex items-center justify-between gap-3 cursor-pointer">
        <div className="flex items-center gap-3">
          <Filter size={20} /> Filters
        </div>

        {isFilterOpen ? (
          <FoldVertical className="group-hover:scale-125 transition-transform" size={16} />
        ) : (
          <UnfoldVertical className="group-hover:scale-125 transition-transform" size={16} />
        )}
      </summary>

      <div className="space-y-5">
        {/* Project */}
        {projects.length ? (
          <ul className="flex items-center gap-x-3 gap-y-2 flex-wrap">
            <li
              className={cn('text-xs border-pixel py-1 px-2 cursor-pointer', {
                [selectedClassName]: isAllProjectsSelected,
              })}
              onClick={onAllProjectsClick}
            >
              전체 프로젝트
            </li>

            {projects.map((project) => (
              <li
                className={cn('text-xs border-pixel py-1 px-2 cursor-pointer flex', {
                  [selectedClassName]: isSelectedProject(project),
                })}
                key={project}
                onClick={() => onProjectClick(project)}
              >
                {project}
              </li>
            ))}
          </ul>
        ) : null}

        {/* Categories */}
        <ul className="flex items-center gap-x-3 gap-y-2 flex-wrap">
          <li
            className={cn('text-xs border-pixel py-1 px-2 cursor-pointer', {
              [selectedClassName]: isAllCategoriesSelected,
            })}
            onClick={onAllCategoriesClick}
          >
            전체 카테고리
          </li>
          {categories.map((category, index) => (
            <li
              className={cn('text-xs border-pixel py-1 px-2 cursor-pointer', {
                [selectedClassName]: isSelectedCategory(category),
              })}
              key={category}
              onClick={() => onCategoryClick(category)}
            >
              <p className="bg-inherit text-inherit">
                <span className="bg-inherit text-inherit">{category}</span>
                {counts?.at(index) ? (
                  <small className="bg-inherit text-inherit">({counts.at(index)})</small>
                ) : null}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </details>
  );
};

export default MonthlyDataFilter;
