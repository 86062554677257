import React from 'react';
import ReactDOM from 'react-dom/client';

import App from '@/app/App.tsx';
import KakaoProvider from '@/app/KakaoProvider';
import SentryProvider from '@/app/SentryProvider';
// import '@sakun/system.css';
import { QueryClientProvider } from '@tanstack/react-query';

import '@/shared/assets/styles/index.css';
import { queryClient } from '@/shared/libs/react-query.lib';

const rootElement = document.getElementById('root');

ReactDOM.createRoot(rootElement!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SentryProvider>
        <KakaoProvider>
          <App />
        </KakaoProvider>
      </SentryProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
