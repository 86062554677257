import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';

import { createDailyReport } from '@/shared/api/graphql/reports/services/create-daily-report.service';
import { deleteDailyReport } from '@/shared/api/graphql/reports/services/delete-daily-report.service';
import { getDailyReportTypes } from '@/shared/api/graphql/reports/services/get-daily-report-types.service';
import { CreateDailyReportFormType } from '@/shared/api/graphql/reports/validators/create-daily-report.validator';
import { type GetDailyReportType } from '@/shared/api/graphql/reports/validators/get-daily-report.validator';
import { getWorks } from '@/shared/api/graphql/works/services/get-works.service';
import { ROUTER_PATH } from '@/shared/constants/routes';

import { usePopupStore } from '@/hooks/states/usePopupStore';

import BottomDrawer, { type BottomDrawerHandlerType } from '@/components/BottomDrawer';
import ConfirmModal from '@/components/ConfirmModal';
import Loader from '@/components/Loader';
import Portal from '@/components/Portal';

import DailyReportEditForm from '@/features/reports/DailyReportEditForm';
import DailyReportTimelineCard, {
  type ReportItemType,
} from '@/features/reports/DailyReportTimelineCard';

type Props = {
  reports: GetDailyReportType[];
  fullDate: string;
};

const ADJUST_PIXEL = 1;

const DailyReportTimeline = ({ fullDate, reports }: Props) => {
  // Constants.
  // const DEFAULT_REPORT_HEIGHT = 60;

  // Hooks.

  const [computedTime, setComputedTime] = useState<string | undefined>();
  const [isTimesReady, setIsTimeReady] = useState<boolean>(false);
  const [indicatorY, setIndicatorY] = useState(0);
  const [reportItems, setReportItems] = useState<ReportItemType[]>([]);
  const [deleteReport, setDeleteReport] = useState<number | undefined>();

  const listItemRef = useRef<HTMLLIElement[]>([]);
  const drawerRef = useRef<BottomDrawerHandlerType[]>([]);
  const currentDrawerIndex = useRef<number | undefined>();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { push } = usePopupStore();

  // Get daily report types query.
  const { data: dailyReportTypesData, isLoading: dailyReportTypesDataIsLoading } = useQuery({
    queryKey: ['dailyReportTypes'],
    queryFn: getDailyReportTypes,
  });

  // Get works list query.
  const { data: worksData, isLoading: worksIsLoading } = useQuery({
    queryKey: ['works'],
    queryFn: getWorks,
  });

  // Mutations.
  const { mutateAsync: createDailyReportMutate, isPending: isCreateDailyReportPending } =
    useMutation({
      mutationFn: createDailyReport,
      onSuccess: () => {
        push({ title: '일일 보고서 생성', subtitle: '정상적으로 생성되었습니다.' });

        queryClient.invalidateQueries();

        // Close bottom drawer.
        drawerRef.current?.[currentDrawerIndex.current ?? 0]?.close();
      },
      onError: (error) => {
        push({ title: '일일 보고서 생성', subtitle: error.message, level: 'error' });
      },
    });
  const isLoading = dailyReportTypesDataIsLoading || worksIsLoading;

  // Delete daily report mutation.
  const { mutateAsync: deleteDailyReportMutate, isPending: isDeleteDailyReportPending } =
    useMutation({
      mutationFn: deleteDailyReport,
      onSuccess: () => {
        push({ title: '삭제', subtitle: '정상적으로 삭제되었습니다.' });

        queryClient.invalidateQueries();
      },
      onError: (error) => {
        push({ title: '삭제', subtitle: error.message, level: 'error' });
      },
    });

  // Functions.

  /**
   * Find current element from elements by hours.
   */
  const foundNowElementByHours = (elements: HTMLLIElement[], hours: string) => {
    const element = elements.find((el) => {
      const text = el?.textContent ?? '';
      const [textHours] = text.split(':');

      return textHours === hours;
    });

    return element;
  };

  /**
   * Compute 'Indicator' height
   */
  const setIndicatorPosition = (element: HTMLLIElement, minutes: string) => {
    const computedHeight = element.offsetTop + Number(minutes);

    setIndicatorY(computedHeight);
  };

  /**
   * Each time click event handler.
   */
  const onTimeClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, time: string) => {
    const target = e.target as HTMLLIElement;
    const y = e.clientY;
    const { top, height } = target.getBoundingClientRect();

    const isTopClicked = y < top + height / 2;
    const [hours, minutes] = time.split(':');

    const computedTime = `${hours}:${isTopClicked ? minutes : '30'}`;

    setComputedTime(computedTime);
  };

  /**
   * Submit event handler.
   */
  const onSubmit = async (form: CreateDailyReportFormType, index?: number) => {
    const { date, startTime, endTime, content, memo, reportTypeId, workId } = form;

    currentDrawerIndex.current = index;

    createDailyReportMutate({
      date,
      startTime,
      endTime,
      content,
      memo,
      reportTypeId: Number(reportTypeId) || null,
      workId: Number(workId) || null,
    });
  };

  /**
   * When each report item click event handler.
   */
  const onReportClick = (item: GetDailyReportType) => {
    navigate(`${ROUTER_PATH.DAILY_REPORT_UPDATE}?id=${item.id}`);
  };

  /**
   * When delete button click event handler.
   */
  const onDeleteClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reportId: number,
  ) => {
    event.stopPropagation();

    setDeleteReport(reportId);
  };

  // Effects.

  // Painting indicator.
  useEffect(() => {
    // Init
    const [hours, minutes] = DateTime.now().toFormat('HH:mm').split(':');

    const element = foundNowElementByHours(listItemRef.current, hours);
    if (element) setIndicatorPosition(element, minutes);

    // Set interval.
    const intervalId = window.setInterval(() => {
      const [hours, minutes] = DateTime.now().toFormat('HH:mm').split(':');

      const element = foundNowElementByHours(listItemRef.current, hours);
      if (element) setIndicatorPosition(element, minutes);
    }, 1000);

    // Focus indicator
    setTimeout(() => {
      const element = foundNowElementByHours(listItemRef.current, hours);
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 1000);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [fullDate]);

  useEffect(() => {
    window.addEventListener('resize', paintReportItems);

    return () => {
      window.removeEventListener('resize', paintReportItems);
    };
  }, []);

  /**
   * Report items dynamic painting.
   */
  const paintReportItems = () => {
    setReportItems([]);

    for (let index = 0; index < reports.length; index++) {
      // Set report.
      const report = reports[index];

      // Parse time.
      const startDateTime = DateTime.fromFormat(report.startTime, 'HH:mm');
      const endDateTime = DateTime.fromFormat(report.endTime, 'HH:mm');
      const diff = endDateTime.diff(startDateTime);
      const diffAsMinutes = diff.milliseconds / 1000 / 60;

      // Find element.
      const element = foundNowElementByHours(
        listItemRef.current,
        String(startDateTime.hour).padStart(2, '0'),
      );
      if (!element) continue;
      const reportsContainer: HTMLDivElement | null = element.querySelector('#reports__container');

      // Find child element.
      if (!reportsContainer) continue;

      // Finally set report items.
      setReportItems((curr) => [
        ...curr,
        {
          report,
          animate: {
            y: reportsContainer.offsetTop + startDateTime.minute - ADJUST_PIXEL,
            x: reportsContainer.offsetLeft + ADJUST_PIXEL,
            width: reportsContainer.offsetWidth,
            height: Math.max(diffAsMinutes),
          },
        },
      ]);
    }
  };

  // Painting reports.
  useEffect(() => {
    paintReportItems();
  }, [reports, isTimesReady]);

  if (isLoading) return <Loader />;

  return (
    <>
      <section>
        <ul className="relative border-pixel z-0">
          {Array.from({ length: 25 })
            .map((_, index) => `${String(index).padStart(2, '0')}:00`)
            .map((time, index) => (
              <li
                ref={(el) => {
                  if (el) listItemRef.current[index] = el;
                  setIsTimeReady(listItemRef.current.length === index + 1 && !!reports.length);
                }}
                key={time}
                className="h-[60px] flex tracking-wider divide-x border-t first:border-t-0"
                onClick={(e) => onTimeClick(e, time)}
              >
                <div className="relative">
                  <BottomDrawer
                    ref={(el) => {
                      if (el) drawerRef.current[index] = el;
                    }}
                    className="h-full !border-none !shadow-none"
                    buttonText={time}
                  >
                    <DailyReportEditForm
                      className="overflow-auto"
                      defaultValues={{
                        date: fullDate,
                        startTime: computedTime,
                      }}
                      dailyReportTypes={dailyReportTypesData}
                      works={worksData}
                      disabled={isCreateDailyReportPending}
                      index={index}
                      onSubmitValid={onSubmit}
                    />
                  </BottomDrawer>
                </div>

                <div id="reports__container" className="flex-1"></div>
              </li>
            ))}

          {/* Indicator */}
          <motion.div
            id="indicator"
            className="absolute top-0 left-0 w-full z-20 flex items-center border-y border-dotted border-red-500 pointer-events-none animate-pulse"
            animate={{ y: indicatorY, transition: { duration: 0.5 } }}
          />

          {/* Report items */}
          <ul className="absolute w-full top-0 left-0">
            {reportItems.map((item, index) => (
              <DailyReportTimelineCard
                key={index}
                item={item}
                onReportClick={onReportClick}
                onDeleteClick={onDeleteClick}
              />
            ))}
          </ul>
        </ul>
      </section>

      <Portal portalId="modal">
        {deleteReport ? (
          <ConfirmModal
            title="리포트 삭제"
            content="정말 해당 리포트를 삭제하시겠어요?"
            isPending={isDeleteDailyReportPending}
            onConfirm={async () => {
              await deleteDailyReportMutate({ id: deleteReport });
              setDeleteReport(undefined);
            }}
            onClose={() => setDeleteReport(undefined)}
            onCancel={() => setDeleteReport(undefined)}
          />
        ) : null}
      </Portal>
    </>
  );
};

export default DailyReportTimeline;
