import { CREATE_FOCUS_MUTATION } from '@/shared/api/graphql/focus/documents/create-focus.document';
import type {
  CreateFocusInputType,
  CreateFocusOutputType,
} from '@/shared/api/graphql/focus/validators/create-focus.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const createFocus = async (input: CreateFocusInputType): Promise<void> => {
  const { createFocus } = await graphqlApiCaller<CreateFocusOutputType>(
    CREATE_FOCUS_MUTATION,
    input,
  );
  if (!createFocus.ok) {
    throw new Error(createFocus.error.message, {
      cause: createFocus.error.code,
    });
  }
};
