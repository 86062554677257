let timeOutId: number;

export const debouncer = <T = unknown>(callback: () => Promise<T> | T, timer = 500) => {
  clearTimeout(timeOutId);
  return new Promise<T>((resolve) => {
    timeOutId = window.setTimeout(() => {
      resolve(callback());
    }, timer);
  });
};
