import { useEffect, useRef, useState } from 'react';

import { DateTime } from 'luxon';

import timerDoneSound from '@/shared/assets/sounds/timer_done.wav';
import { TIMER_STATUS, TimerStatusValues } from '@/shared/constants';
import { minutesToSeconds } from '@/shared/helpers/time.helper';
import { cn } from '@/shared/libs/style.lib';

import ArrowIcon from '@/components/icons/ArrowIcon';
import CheckIcon from '@/components/icons/CheckIcon';
import PauseIcon from '@/components/icons/PauseIcon';
import XIcon from '@/components/icons/XIcon';

export type TimerRunningInfo = {
  timerSeconds: number;
  status: TimerStatusValues;
};

export type TimerDoneInfo = {
  startedAt: DateTime;
  endedAt: DateTime;
  isCompleted: boolean;
};

type Props = {
  defaultMinutes?: number;
  defaultSeconds?: number;
  offsetSeconds?: number;
  onChange?: (info: TimerRunningInfo) => void;
  onDone?: (info: TimerDoneInfo) => void;
};

const CircleTimer = ({
  defaultMinutes = 0,
  defaultSeconds = 0,
  offsetSeconds = 60,
  onDone,
  onChange,
}: Props) => {
  const circleRef = useRef<SVGCircleElement>(null);
  const STROKE_DASH_VALUE = 1231.504320207199;

  const defaultTimerSeconds = minutesToSeconds(defaultMinutes) + defaultSeconds;

  // States.

  // Timer states.
  const [timerSeconds, setTimerSeconds] = useState(defaultTimerSeconds);
  const remainingMinutes = Math.floor(timerSeconds / 60);
  const remainingSeconds = Math.floor(timerSeconds % 60);

  const minutesText = String(remainingMinutes).padStart(2, '0');
  const secondsText = String(remainingSeconds).padStart(2, '0');

  // Status states.
  const [status, setStatus] = useState<TimerStatusValues>(TIMER_STATUS.STOP);

  // Refs.

  const audioRef = useRef<HTMLAudioElement>(null);
  const intervalId = useRef<number | undefined>();
  const targetSeconds = useRef(0);
  const startedAt = useRef<DateTime>(DateTime.now());

  // Effects.

  /**
   * When mounted.
   */
  useEffect(() => {
    init();

    return () => {
      stop();
    };
  }, [defaultMinutes, defaultSeconds]);

  /**
   * When running time.
   */
  useEffect(() => {
    // Timer is done?
    if (timerSeconds <= 0) {
      // Is timer status starting?
      if (status === TIMER_STATUS.START) {
        done();
      }
    } else {
      // Prevent first emitting.
      if (status !== TIMER_STATUS.STOP) {
        paintProgress(timerSeconds, targetSeconds.current);

        // Emit changed
        onChange?.({ timerSeconds: timerSeconds, status });
      }
    }
  }, [timerSeconds]);

  // Handlers.

  const onStart = () => {
    if (status === TIMER_STATUS.PAUSE) resume();
    else start();
  };

  const onPause = () => {
    pause();
  };

  const onStop = () => {
    stop();
  };

  /**
   * When in progress done button clicked event handler.
   */
  const onDoneForced = () => {
    done();
  };

  const onPlusClick = () => {
    setTimerSeconds((current) =>
      current + offsetSeconds > 3600 ? current : (current += offsetSeconds),
    );
  };

  const onMinusClick = () => {
    setTimerSeconds((current) => (current - offsetSeconds <= 0 ? 0 : (current -= offsetSeconds)));
  };

  // Functions.

  function paintProgress(currentSeconds: number, targetSeconds: number) {
    if (!circleRef.current) return;

    const percentageValue = Math.min(1, 1 - currentSeconds / targetSeconds);
    const nextValue = String(STROKE_DASH_VALUE - STROKE_DASH_VALUE * percentageValue);

    circleRef.current.style.strokeDashoffset = nextValue;
  }

  function init() {
    setTimerSeconds(defaultTimerSeconds);

    paintProgress(defaultTimerSeconds, defaultTimerSeconds);
  }

  function start() {
    // Set target milliseconds.
    targetSeconds.current = timerSeconds;

    // Set started at
    startedAt.current = DateTime.now();

    intervalId.current = window.setInterval(() => {
      setTimerSeconds((current) => (current - 1 <= 0 ? 0 : (current -= 1)));
    }, 1000);

    setStatus(TIMER_STATUS.START);
  }

  function resume() {
    intervalId.current = window.setInterval(() => {
      setTimerSeconds((current) => (current - 1 <= 0 ? 0 : (current -= 1)));
    }, 1000);

    setStatus(TIMER_STATUS.START);
  }

  function pause() {
    window.clearInterval(intervalId.current);

    setStatus(TIMER_STATUS.PAUSE);
  }

  function stop() {
    window.clearInterval(intervalId.current);

    init();

    setStatus(TIMER_STATUS.STOP);

    onDone?.({ startedAt: startedAt.current, endedAt: DateTime.now(), isCompleted: false });
  }

  function done() {
    // play finishing sounds.
    audioRef.current?.play();

    stop();

    setStatus(TIMER_STATUS.STOP);

    onDone?.({ startedAt: startedAt.current, endedAt: DateTime.now(), isCompleted: true });
  }

  return (
    <article className="relative flex justify-center items-center">
      {/* Circle area */}
      <div className="flex justify-center items-center">
        <svg className="w-full h-full -rotate-90" viewBox="0 0 400 400">
          <circle cx="200" cy="200" r="196" className="fill-none stroke-green-100 stroke-[8px]" />
          <circle
            ref={circleRef}
            cx="200"
            cy="200"
            r="196"
            className="fill-none stroke-green-500"
            style={{
              strokeWidth: '8px',
              strokeDasharray: STROKE_DASH_VALUE,
              strokeDashoffset: STROKE_DASH_VALUE,
            }}
          />
        </svg>
      </div>

      {/* Center area */}
      <div
        className={cn('absolute flex flex-col gap-2 items-center justify-center', {
          'animate-pulse': status !== TIMER_STATUS.START,
        })}
      >
        <div className="absolute text-6xl tracking-wider flex">
          <div className="relative">
            {status === TIMER_STATUS.STOP && (
              <button className="absolute -left-12 select-none" onClick={onMinusClick}>
                -
              </button>
            )}

            <span>{minutesText}</span>
          </div>

          <span className={cn({ 'animate-blink': status === TIMER_STATUS.START })}>:</span>

          <div className="relative">
            <span>{secondsText}</span>
            {status === TIMER_STATUS.STOP && (
              <button className="absolute -right-12 select-none" onClick={onPlusClick}>
                +
              </button>
            )}
          </div>
        </div>

        <div
          id="actions"
          className="flex items-center mt-32 justify-center gap-2 *:text-sm *:outline-none *:p-2"
        >
          {status === TIMER_STATUS.PAUSE || status === TIMER_STATUS.STOP ? (
            <button
              className="bg-transparent disabled:bg-transparent disabled:cursor-not-allowed disabled:text-red-500"
              disabled={timerSeconds <= 0}
              onClick={onStart}
            >
              <ArrowIcon />
            </button>
          ) : null}

          {status === TIMER_STATUS.START ? (
            <>
              <button className="bg-transparent" onClick={onPause}>
                <PauseIcon />
              </button>
              <button className="bg-transparent" onClick={onStop}>
                <XIcon />
              </button>
              <button className="bg-transparent" onClick={onDoneForced}>
                <CheckIcon />
              </button>
            </>
          ) : null}
        </div>
      </div>

      {/* Etc */}
      <audio ref={audioRef} src={timerDoneSound} />
    </article>
  );
};

export default CircleTimer;
