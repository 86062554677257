import { gql } from 'graphql-request';

export const SET_WORKING_TIME_MUTATION = gql`
  mutation setWorkingTime($input: SetWorkingTimeInput!) {
    setWorkingTime(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;

export const GET_WORKING_TIME_QUERY = gql`
  query workingTime {
    workingTime {
      ok
      error {
        code
        message
      }
      data {
        id
        startTime
        breakTime
        totalTime
        isAutoRefresh
        autoRefreshStartTime
        autoRefreshEndTime
        isDisplayEndTime
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_WORKING_TIME_MUTATION = gql`
  mutation deleteWorkingTime($input: DeleteWorkingTimeInput!) {
    deleteWorkingTime(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;
