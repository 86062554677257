import { GET_ACCOUNTS_QUERY } from '@/shared/api/graphql/accounts/documents/get-accounts.document';
import { type GetAccountOutputDataType } from '@/shared/api/graphql/accounts/validators/get-account.validator';
import { type GetAccountsOutputType } from '@/shared/api/graphql/accounts/validators/get-accounts.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const getAccounts = async (): Promise<GetAccountOutputDataType[]> => {
  const { accounts } = await graphqlApiCaller<GetAccountsOutputType>(GET_ACCOUNTS_QUERY);

  if (!accounts.ok) {
    throw new Error(accounts.error.message, { cause: accounts.error.code });
  }
  return accounts.data;
};
