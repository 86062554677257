import React, { useEffect, useRef } from 'react';

import { usePopupStore } from '@/hooks/states/usePopupStore';

type Props = {
  children: React.ReactNode;
};

const WakeLockProvider = ({ children }: Props) => {
  const { push } = usePopupStore();

  const wakeLock = useRef<WakeLockSentinel>();

  useEffect(() => {
    initWakeLock();

    return () => {
      wakeLock.current?.release();
    };
  }, []);

  async function initWakeLock() {
    if ('wakeLock' in navigator) {
      try {
        wakeLock.current = await navigator.wakeLock.request('screen');
      } catch (err) {
        console.warn(err);

        push({ title: '화면 잠금', subtitle: (err as Error).message, level: 'warning' });
      }
    } else {
      push({
        title: '화면 잠금',
        subtitle: '화면 잠금을 지원하지 않는 기기입니다.',
        level: 'warning',
      });
    }
  }

  return <>{children}</>;
};

export default WakeLockProvider;
