import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTER_PATH } from '@/shared/constants/routes';

import { useAuthStore } from '@/hooks/states/useAuthStore';
import { usePopupStore } from '@/hooks/states/usePopupStore';

const GoogleLoginSuccess = () => {
  const { search } = useLocation();

  const navigate = useNavigate();

  const { accessToken, refreshToken, setAccessToken, setRefreshToken } = useAuthStore();

  const { push } = usePopupStore();

  const params = new URLSearchParams(search);

  useEffect(() => {
    const accessToken = params.get('accessToken');
    const refreshToken = params.get('refreshToken');

    if (accessToken) setAccessToken(accessToken);
    if (refreshToken) setRefreshToken(refreshToken);
  }, []);

  useEffect(() => {
    if (accessToken && refreshToken) {
      push({ title: '구글 로그인', subtitle: '정상적으로 처리되었습니다.' });

      navigate(ROUTER_PATH.HOMER, { replace: true });
    } else {
      navigate(ROUTER_PATH.SIGN_IN, { replace: true });
    }
  }, [accessToken, refreshToken]);
  return <div>Loading</div>;
};

export default GoogleLoginSuccess;
