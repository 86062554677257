import { CoreErrorMessages } from '@/shared/constants/messages';

import { useAuthStore } from '@/hooks/states/useAuthStore';

export const graphqlErrorHandler = (err: unknown) => {
  let message: string = CoreErrorMessages.UNKNOWN_ERROR;

  // Is graphql error?
  if (err && typeof err === 'object' && 'response' in err) {
    if (
      err.response &&
      typeof err.response === 'object' &&
      'errors' in err.response &&
      Array.isArray(err.response.errors)
    ) {
      const firstError: unknown = err.response.errors.at(0);

      // Is response permission Forbidden?
      if (
        firstError &&
        typeof firstError === 'object' &&
        'extensions' in firstError &&
        firstError.extensions &&
        typeof firstError.extensions === 'object' &&
        'code' in firstError.extensions
      ) {
        if (firstError.extensions.code === 'FORBIDDEN') {
          useAuthStore.getState().signOut();
        }
      }

      if (
        firstError &&
        typeof firstError === 'object' &&
        'message' in firstError &&
        typeof firstError.message === 'string'
      ) {
        message = firstError.message;
      }
    }
  }

  return message;
};
