import { AnimatePresence } from 'framer-motion';

import { usePopupStore } from '@/hooks/states/usePopupStore';

import PopupCard from '@/components/popups/PopupCard';

const PopupList = () => {
  const { popups } = usePopupStore();

  return (
    <ul className="space-y-2">
      <AnimatePresence>
        {popups.map((popup) => (
          <PopupCard key={popup.id} popup={popup} />
        ))}
      </AnimatePresence>
    </ul>
  );
};

export default PopupList;
