import { Dispatch, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DateTime } from 'luxon';

import { textCopy } from '@/shared/helpers/shared.helper';
import { cn } from '@/shared/libs/style.lib';
import { TCoreComponent } from '@/shared/types/component.type';

import ArrowIcon from '@/components/icons/ArrowIcon';
import SquareIcon from '@/components/icons/SquareIcon';

export type CalendarDate = {
  year: number;
  month: number;
  day: number;
};

type Props = TCoreComponent & {
  calendarState: [CalendarDate, Dispatch<React.SetStateAction<CalendarDate>>];
};

const CalendarDayInput = ({ className, calendarState }: Props) => {
  const now = DateTime.now();
  const [searchParams, setSearchParams] = useSearchParams();
  const [calendarDate, setCalendarDate] = calendarState;
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!calendarDate) {
      const { year, month, day } = now;
      setCalendarDate({ year, month, day });

      updateSearchParams({ year, month, day });
    }
  }, []);

  /**
   * Synchronize calendar data with search params.
   */
  useEffect(() => {
    const year = Number(searchParams.get('year'));
    const month = Number(searchParams.get('month'));
    const day = Number(searchParams.get('day'));

    if (year) setCalendarDate((current) => ({ ...current, year }));
    if (month) setCalendarDate((current) => ({ ...current, month }));
    if (day) setCalendarDate((current) => ({ ...current, day }));
  }, [searchParams]);

  const parsedMonth = String(calendarDate.month).padStart(2, '0');
  const parsedDay = String(calendarDate.day).padStart(2, '0');
  const fullDate = `${calendarDate.year}-${parsedMonth}-${parsedDay}`;

  const parsedDate = DateTime.fromFormat(fullDate, 'yyyy-MM-dd');

  /**
   * On 'previous day' click event handler.
   */
  const onPreviousDayClick = () => {
    const { year, month, day } = parsedDate.minus({ days: 1 });

    setCalendarDate({ year: year, month: month, day: day });

    updateSearchParams({ year, month, day });

    setIsCopied(false);
  };

  /**
   * On 'today' click event handler.
   */
  const onTodayClick = () => {
    const { year, month, day } = now;

    setCalendarDate({ year: year, month: month, day: day });

    updateSearchParams({ year, month, day });

    setIsCopied(false);
  };

  /**
   * On 'next day' click event handler.
   */
  const onNextDayClick = () => {
    const { year, month, day } = parsedDate.plus({ days: 1 });

    setCalendarDate({ year, month, day });

    updateSearchParams({ year, month, day });

    setIsCopied(false);
  };

  /**
   * Copy full date into clipboard as text.
   */
  const onDateClick = () => {
    textCopy(fullDate);

    setIsCopied(true);
  };

  /**
   * Execute synchronize search params with calendar data by parameters.
   */
  function updateSearchParams({ year, month, day }: CalendarDate) {
    searchParams.set('year', year + '');
    searchParams.set('month', month + '');
    searchParams.set('day', day + '');

    setSearchParams(searchParams, { replace: true });
  }

  return (
    <div className={cn('flex items-center gap-5 border px-4 py-2 border-pixel', className)}>
      <div className="flex items-center gap-2">
        <p
          className={cn('text-sm cursor-pointer', {
            'text-green-500 animate-pulse': isCopied,
          })}
          onClick={onDateClick}
        >
          {fullDate}
        </p>
      </div>

      <div className="flex items-center gap-3">
        <ArrowIcon
          className="size-3 cursor-pointer rotate-180 select-none"
          onClick={onPreviousDayClick}
        />
        <SquareIcon className="size-4 cursor-pointer select-none" onClick={onTodayClick} />
        <ArrowIcon className="size-3 cursor-pointer select-none" onClick={onNextDayClick} />
      </div>
    </div>
  );
};

export default CalendarDayInput;
