import { ComponentProps } from 'react';

import { cn } from '@/shared/libs/style.lib';

type Props = ComponentProps<'img'> & {
  src: string;
};

const Image = ({ className, src, ...rest }: Props) => {
  return (
    <img className={cn('size-5 bg-transparent', className)} src={src} loading="lazy" {...rest} />
  );
};

export default Image;
