import { gql } from 'graphql-request';

export const GET_ACCOUNTS_QUERY = gql`
  query accounts {
    accounts {
      ok
      error {
        code
        message
      }
      data {
        id
        title
        emailOrName
        password
        description
        pictureUrl
      }
    }
  }
`;
