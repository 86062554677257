import { useEffect } from 'react';

import { Environments } from '@/shared/constants/environments.constant';
import { initSentry } from '@/shared/scripts';

type Props = {
  children: React.ReactNode;
};

const SentryProvider = ({ children }: Props) => {
  useEffect(() => {
    // Is dev mode?
    if (Environments.IS_DEV) return;

    // Initialize sentry.
    initSentry();
  }, []);

  return <>{children}</>;
};

export default SentryProvider;
