import { useNavigate } from 'react-router-dom';

import { GetDailyReportType } from '@/shared/api/graphql/reports/validators/get-daily-report.validator';
import { ROUTER_PATH } from '@/shared/constants/routes';

import WeeklyReportItem from '@/features/reports/WeeklyReportItem';

type Props = {
  reports: GetDailyReportType[];
};

const WeeklyReportItems = ({ reports }: Props) => {
  const navigate = useNavigate();

  const onItemClick = (reportId: number) => {
    navigate(`${ROUTER_PATH.DAILY_REPORT_UPDATE}?id=${reportId}`);
  };

  return (
    <div>
      {reports?.length ? (
        <ul className="dynamic-grid">
          {reports.map((report) => (
            <WeeklyReportItem
              key={report.id}
              report={report}
              onItemClick={() => onItemClick(report.id)}
            />
          ))}
        </ul>
      ) : (
        <p className="text-small">
          Create a new daily report item to management your tasks be easier.
        </p>
      )}
    </div>
  );
};

export default WeeklyReportItems;
