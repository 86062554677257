import { ComponentProps } from 'react';

import FullBlackIcon from '@/shared/assets/icons/battery_full_black.png';
import FullWhiteIcon from '@/shared/assets/icons/battery_full_white.png';
import HighBlackIcon from '@/shared/assets/icons/battery_high_black.png';
import HighWhiteIcon from '@/shared/assets/icons/battery_high_white.png';
import LowBlackIcon from '@/shared/assets/icons/battery_low_black.png';
import LowWhiteIcon from '@/shared/assets/icons/battery_low_white.png';
import MiddleBlackIcon from '@/shared/assets/icons/battery_middle_black.png';
import MiddleWhiteIcon from '@/shared/assets/icons/battery_middle_white.png';
import NoneBlackIcon from '@/shared/assets/icons/battery_none_black.png';
import NoneWhiteIcon from '@/shared/assets/icons/battery_none_white.png';

import { useDarkModeStore } from '@/hooks/states/useDarkMode';

import Image from '@/components/elements/Image';

type Props = ComponentProps<'img'> & {
  level?: 'LOW' | 'MIDDLE' | 'HIGH' | 'FULL';
};

const BatteryIcon = ({ level, ...rest }: Props) => {
  const { isDark } = useDarkModeStore();

  const getImageSource = () => {
    switch (level) {
      case 'LOW':
        return isDark ? LowWhiteIcon : LowBlackIcon;
      case 'MIDDLE':
        return isDark ? MiddleWhiteIcon : MiddleBlackIcon;
      case 'HIGH':
        return isDark ? HighWhiteIcon : HighBlackIcon;
      case 'FULL':
        return isDark ? FullWhiteIcon : FullBlackIcon;
      default:
        return isDark ? NoneWhiteIcon : NoneBlackIcon;
    }
  };

  return <Image src={getImageSource()} {...rest} />;
};

export default BatteryIcon;
