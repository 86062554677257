import { DateTime } from 'luxon';

import { GetWorkingTimeOutputDataType } from '@/shared/api/graphql/members/validators';

export const getEndDateTime = (workingTime: GetWorkingTimeOutputDataType) => {
  const endTime = DateTime.fromFormat(workingTime.startTime, 'HH:mm').plus({
    hours: workingTime.totalTime + workingTime.breakTime,
  });

  return endTime;
};

type ComputeLeftTimeOutputType = {
  hours: number;
  minutes: number;
  seconds: number;
};

export const computeLeftTime = (
  workingTime: GetWorkingTimeOutputDataType,
): ComputeLeftTimeOutputType | null => {
  const endTime = getEndDateTime(workingTime);

  // There is no endTime?
  if (!endTime) return null;

  const diff = endTime.diffNow().toMillis();

  const diffInSeconds = Math.floor(diff / 1000);

  const seconds = diffInSeconds % 60;
  const minutes = Math.floor(diffInSeconds / 60) % 60;
  const hours = Math.floor(diffInSeconds / 3600);

  return { hours, minutes, seconds };
};

export const getFullyRelativeTime = (
  workingTime: GetWorkingTimeOutputDataType,
  leftTime?: ComputeLeftTimeOutputType,
) => {
  const time = leftTime ?? computeLeftTime(workingTime);

  // There is no time data?
  if (!time) return '-';

  const { hours, minutes, seconds } = time;

  let result = '';
  if (hours > 0) {
    result += `${String(hours).padStart(2, '0')}시간 `;
  }

  if (minutes > 0) {
    result += `${String(minutes).padStart(2, '0')}분 `;
  }

  if (seconds > 0 || result === '') {
    result += `${String(seconds > 0 ? seconds : 0).padStart(2, '0')}초`;
  }

  return result.trim() ?? '-';
};
