import { type RadioInputOption } from '@/components/inputs/RadioInput';

export const REPORT_CREATION_TYPE = {
  FORM: 'FORM',
  TIMELINE: 'TIMELINE',
} as const;

export type ReportCreationTypeFormType = {
  type: keyof typeof REPORT_CREATION_TYPE;
};

export const creationTypeOptions: RadioInputOption[] = [
  { value: REPORT_CREATION_TYPE.FORM, label: REPORT_CREATION_TYPE.FORM },
  { value: REPORT_CREATION_TYPE.TIMELINE, label: REPORT_CREATION_TYPE.TIMELINE },
];

export const DAILY_REPORT_VIEW_TYPE = {
  CARD: 'CARD',
  TIMELINE: 'TIMELINE',
} as const;

export const DAILY_REPORT_VIEW_TEXT = {
  CARD: '카드 보기',
  TIMELINE: '타임라인 보기',
} as const;
