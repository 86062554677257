export const ProgressStatus = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  DONE: 'DONE',
} as const;

export type ProgressStatusType = keyof typeof ProgressStatus;

export const Priority = {
  HIGH: 'HIGH',
  MIDDLE: 'MIDDLE',
  LOW: 'LOW',
} as const;

export type PriorityType = keyof typeof Priority;
