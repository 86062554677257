import { z } from 'zod';

import { CoreOutput } from '@/shared/types/api.type';

export const ReportTypeSchema = z
  .object({
    id: z.number(),
    name: z.string(),
  })
  .nullish();

export const GetDailyReportInputSchema = z.object({
  id: z.number(),
});

export type GetDailyReportInputType = z.infer<typeof GetDailyReportInputSchema>;

export const GetDailyReportOutputSchema = z.object({
  id: z.number(),
  content: z.string(),
  date: z.string(),
  /** @deprecated */
  time: z.string(),
  startTime: z.string(),
  endTime: z.string(),
  createdAt: z.string(),
  memo: z.string().optional(),
  reportType: ReportTypeSchema,
  work: z
    .object({
      id: z.number(),
      name: z.string(),
      description: z.string(),
      project: z
        .object({
          id: z.number(),
          name: z.string(),
          description: z.string(),
        })
        .nullable(),
    })
    .nullable(),
});

export type GetDailyReportType = z.infer<typeof GetDailyReportOutputSchema>;

export type GetDailyReportOutputType = {
  dailyReport: CoreOutput<GetDailyReportType>;
};
