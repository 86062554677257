import { ComponentProps } from 'react';

import BlackIcon from '@/shared/assets/icons/dot_black.png';
import WhiteIcon from '@/shared/assets/icons/dot_white.png';

import { useDarkModeStore } from '@/hooks/states/useDarkMode';

import Image from '@/components/elements/Image';

type Props = ComponentProps<'img'>;

const SquareIcon = (props: Props) => {
  const { isDark } = useDarkModeStore();

  return <Image src={isDark ? WhiteIcon : BlackIcon} {...props} />;
};

export default SquareIcon;
