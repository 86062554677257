import { useEffect, useState } from 'react';

import WeeklyReportItems from '@/features/reports/WeeklyReportItems';
import { useQuery } from '@tanstack/react-query';
import { Clipboard } from 'lucide-react';
import { DateTime } from 'luxon';

import { getWeeklyReports } from '@/shared/api/graphql/reports/services/get-weekly-reports.service';
import { DateFormat } from '@/shared/constants/formats.constant';
import { makeCopyReportText, textCopy } from '@/shared/helpers/shared.helper';
import { debouncer } from '@/shared/libs/debouncer.lib';

import Button from '@/components/Button';
import PicChart from '@/components/charts/PieChart';
import CalendarPeriodInput from '@/components/inputs/CalendarPeriodInput';

export type TDateRange = {
  start: string;
  end: string;
};

const WeeklyReportList = () => {
  const now = DateTime.now();
  const defaultStartDate = now.startOf('week').toFormat(DateFormat);
  const defaultEndDate = now.endOf('week').toFormat(DateFormat);

  const datePeriodState = useState<TDateRange>({
    start: defaultStartDate,
    end: defaultEndDate,
  });

  const [datePeriod] = datePeriodState;

  const [debouncedDate, setDebouncedDate] = useState<TDateRange>();

  useEffect(() => {
    refreshDate(datePeriod);
  }, [datePeriod]);

  const { data: weeklyReportsData } = useQuery({
    queryKey: ['weeklyReports', debouncedDate],
    queryFn: () =>
      debouncedDate
        ? getWeeklyReports({
            start: debouncedDate.start,
            end: debouncedDate.end,
          })
        : null,
    enabled: !!debouncedDate,
  });

  const onCopyAllReportsClick = () => {
    const texts: string[] = [];
    weeklyReportsData?.reports.forEach((report) => {
      const text = makeCopyReportText(report);
      texts.push(text);
    });
    const joinedText = texts.join('\n\n');

    textCopy(joinedText);
  };

  async function refreshDate(date: TDateRange) {
    const debounced = await debouncer(() => date);
    setDebouncedDate(debounced);
  }

  return (
    <>
      <section>
        <div className="flex items-center justify-between">
          <h1 className="font-bold tracking-wider">주간 보고서</h1>

          <Button className="flex items-center gap-2" onClick={onCopyAllReportsClick}>
            <Clipboard className="text-gray-500" size={18} />
            전체 복사
          </Button>
        </div>
      </section>

      <section className="sticky top-16 z-10">
        <CalendarPeriodInput datePeriodState={datePeriodState} />
      </section>

      <section>
        <PicChart
          labels={weeklyReportsData?.statistics.labels ?? []}
          data={weeklyReportsData?.statistics.series ?? []}
        />
      </section>

      <section>
        <WeeklyReportItems reports={weeklyReportsData?.reports ?? []} />
      </section>
    </>
  );
};

export default WeeklyReportList;
