import { ComponentProps } from 'react';

import { motion } from 'framer-motion';

import { cn } from '@/shared/libs/style.lib';

import SquareIcon from '@/components/icons/SquareIcon';

type Props = ComponentProps<'div'>;

const Loader = ({ className, ...rest }: Props) => {
  return (
    <div
      className={cn('flex justify-center items-center gap-1 h-full *:size-2', className)}
      {...rest}
    >
      <motion.div
        className="relative flex justify-center items-center"
        initial={{ y: 0 }}
        animate={{ y: 8 }}
        transition={{ repeat: Infinity, duration: 0.5, repeatType: 'reverse' }}
      >
        <SquareIcon className="absolute size-2" />
      </motion.div>
      <motion.div
        className="relative flex justify-center items-center"
        initial={{ y: 0 }}
        animate={{ y: 8 }}
        transition={{ repeat: Infinity, duration: 0.5, repeatType: 'reverse', delay: 0.3 }}
      >
        <SquareIcon className="absolute size-2" />
      </motion.div>
      <motion.div
        className="relative flex justify-center items-center"
        initial={{ y: 0 }}
        animate={{ y: 8 }}
        transition={{ repeat: Infinity, duration: 0.5, repeatType: 'reverse', delay: 0.6 }}
      >
        <SquareIcon className="absolute size-2" />
      </motion.div>
    </div>
  );
};

export default Loader;
