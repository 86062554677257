import { gql } from 'graphql-request';

export const GET_MONTHLY_REPORTS_QUERY = gql`
  query monthlyReports($input: GetMonthlyReportsInput!) {
    monthlyReports(input: $input) {
      ok
      error {
        code
        message
      }
      data {
        reports {
          id
          content
          date
          time
          memo
          createdAt
          reportType {
            id
            name
          }
          work {
            name
            project {
              name
            }
          }
        }
        charts {
          series
          labels
        }
      }
    }
  }
`;
