import { ComponentProps } from 'react';

import BlackIcon from '@/shared/assets/icons/pause_black.png';
import WhiteIcon from '@/shared/assets/icons/pause_white.png';

import { useDarkModeStore } from '@/hooks/states/useDarkMode';

import Image from '@/components/elements/Image';

type Props = ComponentProps<'img'>;

const PauseIcon = (props: Props) => {
  const { isDark } = useDarkModeStore();

  return <Image src={isDark ? WhiteIcon : BlackIcon} {...props} />;
};

export default PauseIcon;
