/**
 * Convert milliseconds to formatted relative time.
 */
export const millisecondsToRelativeTime = (milliseconds: number) => {
  const seconds = milliseconds / 1000;
  const minutes = Math.max(0, Math.floor(seconds / 60));
  const remainedSeconds = Math.max(0, seconds % 60);

  return {
    minutes: String(minutes).padStart(2, '0'),
    seconds: String(remainedSeconds).padStart(2, '0'),
  };
};

export const asMilliseconds = (minutes?: number, seconds?: number) =>
  minutesToMilliseconds(minutes) + secondToMilliseconds(seconds);

/**
 * Convert unit minutes to seconds.
 */
export const minutesToSeconds = (minutes: number = 0) => minutes * 60;

/**
 * Convert unit seconds to milliseconds.
 */
export const secondToMilliseconds = (seconds: number = 0) => seconds * 1000;

/**
 * Convert unit minutes to milliseconds.
 */
export const minutesToMilliseconds = (minutes: number = 0) =>
  secondToMilliseconds(minutesToSeconds(minutes));
