import { ComponentProps } from 'react';
import type { UseFormRegisterReturn, UseFormWatch } from 'react-hook-form';

import { cn } from '@/shared/libs/style.lib';

import useSubmitShortcut from '@/hooks/inputs/useSubmitShortcut';

type Props = ComponentProps<'input'> & {
  register?: UseFormRegisterReturn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch?: UseFormWatch<any>;
  error?: string;
  actionButton?: React.ReactNode;
  inputWrapperClassName?: string;
  showTextLength?: boolean;

  onSubmit?: () => void | Promise<void>;
};

const TextInput = ({
  className,
  inputWrapperClassName,
  id,
  children,
  watch,
  register,
  error,
  actionButton,
  showTextLength = true,
  onSubmit,
  ...rest
}: Props) => {
  // Hooks.
  const { onKeyDown, onKeyUp } = useSubmitShortcut<HTMLInputElement>({ onSubmit });

  const currentText = watch?.(register?.name ?? '');

  return (
    <div className={cn('space-y-2', inputWrapperClassName)}>
      {children ? <label htmlFor={id}>{children}</label> : null}

      <div className="flex items-center justify-between gap-2">
        <div className="w-full flex items-center gap-2 justify-between relative">
          <input
            id={id}
            type="text"
            className={cn(
              'px-3 py-1.5 min-w-[50px] grow disabled:cursor-not-allow border-pixel',
              className,
            )}
            autoCapitalize="off"
            autoComplete="off"
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            {...rest}
            {...register}
          />

          {/* Current text length. */}
          {currentText && showTextLength ? (
            <small className="absolute right-2 text-gray-400 tracking-widest bg-gray-100 px-1 py-0.5">
              {currentText.length}
              {rest.maxLength ? ` / ${rest.maxLength}` : ''}
            </small>
          ) : null}
        </div>

        {actionButton}
      </div>

      {error ? <p className="error">{error}</p> : null}
    </div>
  );
};

export default TextInput;
