import { z } from 'zod';

import { ReportMessages } from '@/shared/constants/messages/reports.constant';
import { CoreOutput } from '@/shared/types/api.type';

export const createDailyReportInputSchema = z.object({
  date: z.string().min(1, ReportMessages.ENTER_DATE),
  startTime: z.string().min(1, ReportMessages.ENTER_TIME),
  endTime: z.string().min(1, ReportMessages.ENTER_TIME),
  content: z.string().min(1, ReportMessages.ENTER_CONTENT),
  reportTypeId: z.number().nullish(),
  memo: z.string().max(300, ReportMessages.MEMO_LESS_OR_EQUALS_THAN_300).nullish(),
  workId: z.number().nullish(),
});

export type CreateDailyReportInputType = z.infer<typeof createDailyReportInputSchema>;

export type CreateDailyReportOutputType = {
  createDailyReport: CoreOutput;
};

export const createDailyReportFormSchema = createDailyReportInputSchema.extend({
  reportTypeId: z.string().optional(),
  workId: z.string().optional(),
});

export type CreateDailyReportFormType = z.infer<typeof createDailyReportFormSchema>;
