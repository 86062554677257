import { z } from 'zod';

import { CoreOutput } from '@/shared/types/api.type';

export const CreateFocusInputSchema = z.object({
  startedAt: z.string(),
  endedAt: z.string(),
  memo: z.string().optional(),
  taskId: z.number().optional(),
});

export type CreateFocusInputType = z.infer<typeof CreateFocusInputSchema>;

export const FocusEditFormSchema = CreateFocusInputSchema.extend({
  taskId: z.string().optional(),
});

export type FocusEditFormType = z.infer<typeof FocusEditFormSchema>;

export type CreateFocusOutputType = {
  createFocus: CoreOutput;
};
