import { RequestDocument, Variables } from 'graphql-request';

import { graphqlErrorHandler } from '@/shared/libs/api.lib';
import { graphqlClient } from '@/shared/libs/graphql-request.lib';

import { useAuthStore } from '@/hooks/states/useAuthStore';

export const graphqlApiCaller = async <T = unknown>(
  document: RequestDocument,
  input?: Variables,
) => {
  try {
    const { accessToken } = useAuthStore.getState();
    const response = await graphqlClient.request<T>(
      document,
      {
        input,
      },
      {
        ...(accessToken && { authorization: `Bearer ${useAuthStore.getState().accessToken}` }),
      },
    );

    return response;
  } catch (err) {
    throw new Error(graphqlErrorHandler(err));
  }
};
