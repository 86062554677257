import { DELETE_ACCOUNT_MUTATION } from '@/shared/api/graphql/accounts/documents/delete-account.document';
import type {
  DeleteAccountInputType,
  DeleteAccountOutputType,
} from '@/shared/api/graphql/accounts/validators/delete-account.validator';
import { graphqlApiCaller } from '@/shared/helpers/api.helper';

export const deleteAccount = async (input: DeleteAccountInputType) => {
  const { deleteAccount } = await graphqlApiCaller<DeleteAccountOutputType>(
    DELETE_ACCOUNT_MUTATION,
    input,
  );
  if (!deleteAccount.ok) {
    throw new Error(deleteAccount.error.message, {
      cause: deleteAccount.error.code,
    });
  }
};
