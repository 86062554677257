import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { TMe } from '@/shared/api/graphql/members/validators/me.validator';

export type TUseAuthState = {
  accessToken?: string;
  refreshToken?: string;
  setAccessToken: (accessToken: string) => void;
  setRefreshToken: (refreshToken: string) => void;
  signOut: () => void;
  me?: TMe;
  setMe: (me: TMe) => void;
};

export const useAuthStore = create<TUseAuthState>()(
  persist(
    (set) => ({
      setAccessToken: (accessToken) => set({ accessToken }),
      setRefreshToken: (refreshToken) => set({ refreshToken }),
      signOut: () => set({ accessToken: undefined, refreshToken: undefined }),
      setMe: (me) => set({ me }),
    }),
    {
      name: 'vvorkmon-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export type TUseLocalAuthState = {
  email?: string;
  setEmail: (email: string) => void;
  clearEmail: () => void;
};

export const useLocalAuthStore = create<TUseLocalAuthState>()(
  persist(
    (set) => ({
      email: undefined,
      setEmail: (email) => set({ email }),
      clearEmail: () => set({ email: undefined }),
    }),
    {
      name: 'vvorkmon-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
