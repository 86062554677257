import { ComponentProps } from 'react';

import { motion, MotionProps } from 'framer-motion';

import { cn } from '@/shared/libs/style.lib';

import { useDarkModeStore } from '@/hooks/states/useDarkMode';

type Props = ComponentProps<'button'> & {
  motionProps?: MotionProps;
};

const Button = (input: Props) => {
  const { className, children, motionProps, ...rest } = input;

  const { isDark } = useDarkModeStore();

  return (
    <motion.button
      className={cn(
        'flex items-center justify-center border-[1.5px] px-3 py-1.5 border-black text-sm disabled:cursor-not-allowed disabled:text-gray-400 whitespace-nowrap border-pixel',
        isDark ? 'hover:bg-slate-500' : 'hover:bg-slate-200',
        className,
      )}
      {...motionProps}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(rest as any)}
    >
      {children}
    </motion.button>
  );
};

export default Button;
