import { Suspense, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { createFocus } from '@/shared/api/graphql/focus/services/create-focus.service';
import {
  FocusEditFormSchema,
  FocusEditFormType,
} from '@/shared/api/graphql/focus/validators/create-focus.validator';
import { createDailyReport } from '@/shared/api/graphql/reports/services/create-daily-report.service';
import { getTasks } from '@/shared/api/graphql/tasks/services/get-tasks.service';
import { TIMER_STATUS, TimerStatusValues } from '@/shared/constants';
import { ROUTER_PATH } from '@/shared/constants/routes';

import { usePopupStore } from '@/hooks/states/usePopupStore';

import Button from '@/components/Button';
import CircleTimer, { TimerDoneInfo, TimerRunningInfo } from '@/components/CircleTimer';
import QuestionIcon from '@/components/icons/QuestionIcon';
import SelectInput from '@/components/inputs/SelectInput';
import TextAreaInput from '@/components/inputs/TextAreaInput';
import Loader from '@/components/Loader';

const DEFAULT_TIMER_MINUTES = 5;

const PomodoroTimer = () => {
  // Constants.

  // States.

  const [timerMinutes, setTimerMinutes] = useState(DEFAULT_TIMER_MINUTES);
  const [timerStatus, setTimerStatus] = useState<TimerStatusValues>(TIMER_STATUS.STOP);

  // Hooks.

  const navigate = useNavigate();

  const { push } = usePopupStore();

  const {
    register,
    watch,
    getValues,
    formState: { errors },
  } = useForm<FocusEditFormType>({
    resolver: zodResolver(FocusEditFormSchema),
    defaultValues: {
      taskId: '',
      memo: '',
    },
  });

  const watchedTaskId = watch('taskId');

  const queryClient = useQueryClient();

  // Getting task list query.
  const { data: tasksData } = useQuery({
    queryKey: ['tasks'],
    queryFn: getTasks,
  });

  // Create focus mutation.
  const { mutateAsync: createFocusMutate, isPending: createFocusIsPending } = useMutation({
    mutationFn: createFocus,
    onSuccess: () => {
      push({ title: '포커스 생성', subtitle: '정상적으로 저장되었습니다.' });
      queryClient.invalidateQueries({ queryKey: ['focuses'] });
    },
    onError: (error) => {
      push({ title: '포커스 생성', subtitle: error.message, level: 'warning' });
    },
  });

  // Create daily report mutation.
  const { mutateAsync: createDailyReportMutate, isPending: createDailyReportIsPending } =
    useMutation({
      mutationFn: createDailyReport,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['dailyReports'] });
      },
      onError: (error) => {
        push({ title: '리포트 생성', subtitle: error.message, level: 'warning' });
      },
    });

  // Effects.

  // When task id changed.
  useEffect(() => {
    if (watchedTaskId) {
      const task = tasksData?.find((task) => task.id === Number(watchedTaskId));
      if (!task?.estimatedMinutes) return;

      setTimerMinutes(task.estimatedMinutes);
    } else {
      setTimerMinutes(DEFAULT_TIMER_MINUTES);
    }
  }, [watchedTaskId]);

  // Handlers.

  /**
   * When time value changed.
   */
  const onTimerChange = ({ status }: TimerRunningInfo) => {
    setTimerStatus(status);
  };

  /**
   * When timer done.
   */
  const onTimerDone = ({ startedAt, endedAt, isCompleted }: TimerDoneInfo) => {
    setTimerStatus(TIMER_STATUS.STOP);

    if (isCompleted) {
      savePomodoroFocus(startedAt, endedAt);
      saveReport(startedAt, endedAt);
    }
  };

  /**
   * Save focus data.
   */
  async function savePomodoroFocus(startDateTime?: DateTime, endDateTime?: DateTime) {
    if (createFocusIsPending) return;

    const startIso = startDateTime?.toISO();
    const endIso = endDateTime?.toISO();

    if (!startIso || !endIso) {
      push({
        title: '포커스 저장',
        subtitle: '포커스 시작/종료 시간에 오류가 발생했습니다.',
        level: 'error',
      });
      return;
    }

    createFocusMutate({
      startedAt: startIso,
      endedAt: endIso,
      taskId: Number(getValues('taskId')),
      memo: getValues('memo'),
    });
  }

  /**
   * Save report data.
   */
  async function saveReport(startDateTime?: DateTime, endDateTime?: DateTime) {
    if (createDailyReportIsPending) return;
    const startedAtTime = startDateTime?.toFormat('HH:mm');
    const endedAtTime = endDateTime?.toFormat('HH:mm');

    if (!startedAtTime || !endedAtTime) {
      push({
        title: '리포트 저장',
        subtitle: '포커스 시작/종료 시간에 오류가 발생했습니다.',
        level: 'error',
      });
      return;
    }

    createDailyReportMutate({
      date: DateTime.now().toFormat('yyyy-MM-dd'),
      startTime: startedAtTime,
      endTime: endedAtTime,
      content: getValues('memo') || '포커스',
    });
  }

  return (
    <>
      <section className="space-y-4">
        <section className="flex items-center justify-between gap-5">
          <h1 className="font-bold tracking-wider">포모도로 타이머</h1>

          <section className="flex items-center gap-5">
            <Button
              disabled={timerStatus !== TIMER_STATUS.STOP}
              onClick={() => {
                navigate(ROUTER_PATH.POMODORO_STATISTICS);
              }}
            >
              통계
            </Button>
          </section>
        </section>

        <div className="flex items-stretch gap-2">
          <QuestionIcon />
          <p className="text-gray-500 font-light text-xs space-y-4">
            포모도로 타이머와 함께 효율적으로 시간을 관리해보세요.
          </p>
        </div>
      </section>

      {/* Task choosing list */}
      <Suspense fallback={<Loader />}>
        <section>
          <SelectInput
            register={register('taskId')}
            options={
              tasksData
                ?.filter((task) => task.status !== 'DONE')
                .map((task) => ({
                  label: `${task.title} (${task.estimatedMinutes ?? '?'} 분)`,
                  value: task.id,
                })) ?? []
            }
            disabled={timerStatus !== TIMER_STATUS.STOP}
            withNoneOption
          />
        </section>
      </Suspense>

      {/* Timer */}
      <section>
        <CircleTimer
          defaultMinutes={timerMinutes}
          offsetSeconds={60}
          onChange={onTimerChange}
          onDone={onTimerDone}
        />
      </section>

      {/* Memo */}
      <section>
        <TextAreaInput
          className="text-sm tracking-wider"
          placeholder="메모를 입력하세요"
          register={register('memo')}
          disabled={timerStatus !== TIMER_STATUS.STOP}
          error={errors.memo?.message}
          watch={watch}
          maxLength={100}
          rows={4}
        />
      </section>
    </>
  );
};

export default PomodoroTimer;
