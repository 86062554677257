import { z } from 'zod';

import { CoreOutput } from '@/shared/types/api.type';

// Setter.
export const setWorkingTimeInputSchema = z.object({
  startTime: z.string().min(1, '출근 시간을 입력해주세요.'),
  breakTime: z.number({ required_error: '휴식 시간을 입력해주세요.' }),
  totalTime: z.number({ required_error: '총 근무 시간을 입력해주세요.' }),
  isAutoRefresh: z.boolean(),
  isDisplayEndTime: z.boolean(),
  autoRefreshStartTime: z.string().optional(),
  autoRefreshEndTime: z.string().optional(),
});

export type SetWorkingTimeInputType = z.infer<typeof setWorkingTimeInputSchema>;

export type SetWorkingTimeOutputType = {
  setWorkingTime: CoreOutput;
};

export const workingTimeForm = setWorkingTimeInputSchema.extend({
  breakTime: z.string().min(1, '휴식 시간을 입력해주세요.'),
  totalTime: z.string().min(1, '총 근무 시간을 입력해주세요.'),
});

export type WorkingTimeForm = z.infer<typeof workingTimeForm>;

// Remover.
export const deleteWorkingTimeInputSchema = z.object({
  id: z.number(),
});

export type DeleteWorkingTimeInputType = z.infer<typeof deleteWorkingTimeInputSchema>;

export type DeleteWorkingTimeOutputType = {
  deleteWorkingTime: CoreOutput;
};

// Getter.
export const getWorkingTimeOutputSchema = z.object({
  id: z.number(),
  startTime: z.string(),
  breakTime: z.number(),
  totalTime: z.number(),
  isAutoRefresh: z.boolean(),
  autoRefreshStartTime: z.string(),
  autoRefreshEndTime: z.string(),
  isDisplayEndTime: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type GetWorkingTimeOutputDataType = z.infer<typeof getWorkingTimeOutputSchema>;

export type GetWorkingTimeOutputType = {
  workingTime: CoreOutput<GetWorkingTimeOutputDataType>;
};
