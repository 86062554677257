import { Link } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getMeetings } from '@/shared/api/graphql/meetings/services/get-meetings.service';
import { ROUTER_PATH } from '@/shared/constants/routes';

import Button from '@/components/Button';
import QuestionIcon from '@/components/icons/QuestionIcon';
import Loader from '@/components/Loader';

import MeetingListItems from '@/features/meetings/MeetingListItems';

const MeetingList = () => {
  const { data: meetingsData, isLoading: meetingsIsLoading } = useQuery({
    queryKey: ['meetings'],
    queryFn: getMeetings,
  });

  return (
    <>
      <section className="space-y-2">
        <div className="flex items-center justify-between gap-5">
          <div className="flex items-center gap-5">
            <h1 className="font-bold tracking-wider">회의 목록</h1>
          </div>

          <Link className="no-link" to={ROUTER_PATH.MEETING_CREATE}>
            <Button>추가</Button>
          </Link>
        </div>

        <div className="flex items-stretch gap-2">
          <QuestionIcon />
          <p className="text-gray-500 font-light text-xs">
            새로운 회의을 추가하고, 손 쉽게 관리하세요!
          </p>
        </div>
      </section>

      <section>
        {meetingsIsLoading ? <Loader /> : <MeetingListItems meetings={meetingsData} />}
      </section>
    </>
  );
};

export default MeetingList;
