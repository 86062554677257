import { gql } from 'graphql-request';

export const ME_QUERY = gql`
  query me {
    me {
      ok
      error {
        code
        message
      }
      data {
        id
        email
        name
        timeZone
        avatarUrl
        provider
        updatedAt
        createdAt
      }
    }
  }
`;
