import { motion } from 'framer-motion';
import { DateTime } from 'luxon';

import type { TypeTask } from '@/shared/api/graphql/tasks/validators/get-task.validator';
import { DATE_FORMAT } from '@/shared/constants';
import { Priority, ProgressStatus } from '@/shared/constants/tasks.constant';
import { calculateDateDifferencePercentage } from '@/shared/helpers/date.helper';
import { cn } from '@/shared/libs/style.lib';

import DeleteIcon from '@/components/icons/DeleteIcon';
import EditIcon from '@/components/icons/EditIcon';

type Props = {
  task: TypeTask;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
};

const TaskItem = ({ task, onEditClick, onDeleteClick }: Props) => {
  const percentage = calculateDateDifferencePercentage(
    DateTime.fromISO(task.createdAt).startOf('day').toJSDate(),
    DateTime.fromFormat(task.due, DATE_FORMAT).endOf('day').toJSDate(),
    DateTime.now().toJSDate(),
  );

  const isSafety = percentage < 30;
  const isWarning = percentage < 60;
  const isDangerous = percentage > 60;

  const backgroundColor = isSafety
    ? `rgb(34 197 94)`
    : isWarning
      ? 'rgb(249 115 22)'
      : 'rgb(239 68 68)';

  return (
    <li className="border px-4 py-2 overflow-x-auto space-y-2 border-pixel" key={task.id}>
      <div className="flex items-center justify-between">
        {/* Title */}
        <p className="flex-1 text-sm truncate" title={task.title}>
          {task.title}
        </p>

        {/* Actions */}
        <div className="flex items-center gap-3">
          <EditIcon className="cursor-pointer" onClick={onEditClick} />

          <DeleteIcon className="cursor-pointer" onClick={onDeleteClick} />
        </div>
      </div>

      <div className="flex items-center gap-3">
        {/* Memo */}
        <p className="text-gray-500 flex-1 truncate" title={task.memo}>
          {task.memo || '-'}
        </p>

        {/* Due */}
        <small className="text-xs text-gray-500">{task.due}</small>
      </div>

      <div className="flex items-center flex-wrap text-xs gap-2">
        {/* Status */}
        <span
          className={cn(
            'px-2 py-1 border font-bold tracking-wider text-white border-pixel light-ignore dark-ignore',
            { 'bg-gray-500': task.status === ProgressStatus.PENDING },
            { 'bg-blue-500': task.status === ProgressStatus.RUNNING },
            { 'bg-green-500': task.status === ProgressStatus.DONE },
          )}
        >
          {task.status}
        </span>

        {/* Priority */}
        <span
          className={cn(
            'px-2 py-1 border font-bold tracking-wider text-white border-pixel light-ignore dark-ignore',
            { 'bg-red-500': task.priority === Priority.HIGH },
            { 'bg-orange-500': task.priority === Priority.MIDDLE },
            { 'bg-gray-500': task.priority === Priority.LOW },
          )}
        >
          {task.priority ?? '-'}
        </span>
      </div>

      {/* Indicator */}
      <div className="flex h-2 border">
        <motion.div
          className={cn('dark-ignore light-ignore', {
            'animate-pulse': isDangerous,
          })}
          initial={{ width: 0 }}
          animate={{ width: `${percentage}%`, backgroundColor }}
        ></motion.div>
      </div>
    </li>
  );
};

export default TaskItem;
